import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  SaveButton,
  TypeaheadSingleInput,
  TypeaheadTagsInput,
  FormCard,
} from '../../components/forms';
import { _BSFT_EMAIL_CONFIG } from '../../utils/empties';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import {
  useAllQuery,
  useSingleQuery,
} from '../../utils/hooks/reactQuery/queries';

const BsftEmailConfigSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  fromName: Yup.string(),
  fromAddress: Yup.string().email('Must be a valid email format'),
  utmSource: Yup.string(),
  testEmail: Yup.string().email('Must be a valid email format'),
  tags: Yup.array().of(Yup.string()),
  segmentUuid: Yup.string().required('Segment is required'),
  adapterUuid: Yup.string().required('Adapter is required'),
});

function BsftEmailConfig() {
  const { id } = useParams();
  const {
    data = _BSFT_EMAIL_CONFIG,
    isLoading,
    isError,
    error,
  } = useSingleQuery('bsft-email-config', id);
  const { update, add } = useItemMutation('bsft-email-config');
  const { data: adapters = [] } = useAllQuery('bsft-email-adapters');
  const { data: segments = [] } = useAllQuery('bsft-segments');

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving email config...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved email config!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save email config...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved email config!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error('Error Saving...', error.response.data.message);
          toast.update(toastId, {
            render: 'Could not save email config...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/bsft-email-configs"
          breadcrumbLabel="Email Configs"
          breadcrumbItem={data.name.length > 0 ? data.name : 'New Email Config'}
        >
          <Formik
            initialValues={data}
            validationSchema={BsftEmailConfigSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, isValid, isSubmitting }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                  <TypeaheadSingleInput
                    label="Segment"
                    id="segmentUuid"
                    name="segmentUuid"
                    placeholder="Segment"
                    options={segments}
                    labelKey="name"
                    valueKey="uuid"
                    objects
                    renderMenuItemChildren={(option) => (
                      <div>
                        {option.name}
                        <div>
                          <small>Email Users: {option.email_users}</small>
                        </div>
                      </div>
                    )}
                    customOnChange={(selected) => {
                      if (selected !== '') {
                        setFieldValue('userCount', selected.email_users);
                        setFieldValue(
                          'userCountLastUpdated',
                          selected.approxusers_updated_at,
                        );
                        setFieldValue('segmentUuid', selected.uuid, true);
                      } else {
                        setFieldValue('userCount', 0);
                        setFieldValue('userCountLastUpdated', '');
                        setFieldValue('segmentUuid', '', true);
                      }
                    }}
                  />
                  <TypeaheadSingleInput
                    label="Adapter"
                    id="adapterUuid"
                    name="adapterUuid"
                    placeholder="Adapter"
                    options={adapters}
                    labelKey="primary_name"
                    valueKey="uuid"
                    objects
                    renderMenuItemChildren={(option) => (
                      <div>
                        {option.primary_name}
                        <div>
                          <small>{option.name}</small>
                        </div>
                      </div>
                    )}
                  />
                  <BootstrapTextInput
                    label="From Name"
                    id="fromName"
                    name="fromName"
                    placeholder="From Name"
                  />
                  <BootstrapTextInput
                    label="From Address"
                    id="fromAddress"
                    name="fromAddress"
                    placeholder="From Address"
                  />
                  <BootstrapTextInput
                    label="UTM Source"
                    id="utmSource"
                    name="utmSource"
                    placeholder="UTM Source"
                  />
                  <BootstrapTextInput
                    label="Test Email"
                    id="testEmail"
                    name="testEmail"
                    placeholder="Test Email"
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default BsftEmailConfig;
