import React, { createContext, useContext, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faTriangleExclamation,
  faCircleInfo,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons';

const ICONS = {
  error: faCircleExclamation,
  warning: faTriangleExclamation,
  info: faCircleInfo,
  success: faCircleCheck,
};

const COLORS = {
  error: 'cpred-500',
  warning: 'cpyellow-500',
  info: 'cpblue-500',
  success: 'success',
};

const ConfirmActionModalContext = createContext(undefined);

const ConfirmActionModalProvider = ({ children }) => {
  const [confirmActionModal, setConfirmActionModal] = useState({
    open: false,
    title: 'Confirm?',
    severity: 'warning',
    message: '',
  });
  const value = { confirmActionModal, setConfirmActionModal };

  const handleClose = (confirmed) => {
    setConfirmActionModal((old) => ({ ...old, open: false }));

    if (confirmActionModal.onClose) {
      confirmActionModal.onClose(confirmed);
    }
  };

  return (
    <ConfirmActionModalContext.Provider value={value}>
      {children}
      <Modal
        centered
        isOpen={confirmActionModal.open}
        toggle={() => handleClose(false)}
      >
        <ModalHeader
          className={`text-bg-${COLORS[confirmActionModal.severity]}`}
        >
          <FontAwesomeIcon icon={ICONS[confirmActionModal.severity]} />{' '}
          <span className="ms-2">{confirmActionModal.title}</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>{confirmActionModal.message}</p>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <Button
                block
                color="cpblue-500"
                onClick={() => handleClose(true)}
              >
                Yes
              </Button>
              <Button
                block
                className="mt-2"
                color="cpblue-100"
                onClick={() => handleClose(false)}
              >
                No
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </ConfirmActionModalContext.Provider>
  );
};

const useConfirmActionModal = () => {
  const context = useContext(ConfirmActionModalContext);
  if (context === undefined) {
    throw new Error(
      'useConfirmActionModal must be used within a ConfirmActionModalProvider',
    );
  }
  return context;
};

export { ConfirmActionModalProvider, useConfirmActionModal };
