import { htmlToEmail } from './htmlParse';

export const buildHtmlEmail = (html, template, options) => {
  // First let's grab our current template and clean up our html
  let emailTemplate = template;
  let emailFriendlyHtml = htmlToEmail(html);

  // We can simply start by replacing $content with our newly parsed HTML
  emailTemplate = emailTemplate.replace('$content', emailFriendlyHtml);

  // Next we will iterate through all of our options and place their values in the template
  for (let option of options) {
    // look for $option-key and replace with option.value
    emailTemplate = emailTemplate.replace(`$${option.key}`, option.value);
  }

  // Done! Let's return our template complete with all options and content!
  return emailTemplate;
};
