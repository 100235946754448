// Function to format the final html to be prettier
export function formatHtml(html) {
  html = html.replace(/&#x27;/g, "'");
  html = html.replace(/&#x60;/g, '`');
  html = html.replace(/&lt;/g, '<');
  html = html.replace(/&gt;/g, '>');
  html = html.replace(/&amp;/g, '&');
  html = html.replace(/&nbsp;/g, ' ');
  html = html.replace(/>\s+</g, '><');
  html = html.replace(/>\n</g, '><');
  // i want to remove any whitespace before a closing p tag
  // also remove any whitespace after an opening p tag
  html = html.replace(/\s+<\/p>/g, '</p>');
  html = html.replace(/<p>\s+/g, '<p>');
  // let's also remove any unnecessary line breaks
  html = html.replace(/\n/g, ' ');

  // now let's format the html code to make it more readable
  html = html.replace(/\/p><p/g, '/p>\n<p'); // opening and closing p tags
  html = html.replace(/\/p><img/g, '/p>\n<img'); // closing p and image tags
  html = html.replace(/><p/g, '>\n<p'); // closing image and opening p tags
  html = html.replace(/<\/blockquote><blockquote>/g, ''); // closing blockquote and opening blockquote tags
  html = html.replace(/><blockquote/g, '>\n<blockquote'); // closing tags and opening blockquote tags
  html = html.replace(/\/p><\/blockquote/g, '/p>\n</blockquote'); // closing p and closing blockquote tags
  html = html.replace(/\/blockquote></g, '/blockquote>\n<'); // closing blockquote and opening tags
  html = html.replace(/\/ul><p/g, '/ul>\n<p'); // closing ul and opening p tags
  html = html.replace(/\/ol><p/g, '/ol>\n<p'); // closing ol and opening p tags
  html = html.replace(/\/p><ul/g, '/p>\n<ul'); // closing p and opening ul tags
  html = html.replace(/\/p><ol/g, '/p>\n<ol'); // closing p and opening ol tags
  html = html.replace(/><li/g, '>\n<li'); // closing tags and li tags
  html = html.replace(/\/li></g, '/li>\n<'); // closing li and opening tags

  // any closing and opening inline tags should just be spaces
  html = html.replace(/<\/em><em>/g, ' ');
  html = html.replace(/<\/strong><strong>/g, ' ');
  html = html.replace(/<\/u><u>/g, ' ');

  // lastly I just want to remove any double spaces
  html = html.replace(/\s{2,}/g, ' '); // make sure we didn't add any double spaces anywhere

  return html;
}

// Function to remove the view current portfolio now link
export function removeViewPort(html, viewPort) {
  if (viewPort !== '') {
    html = html.replace(viewPort, '');
    return html;
  }
  return html;
}
