import React, { useState, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupText,
  Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../utils/contexts/userContext';
import { login as jwtLogin } from '../utils/jwt';
import { baseUrl } from '../utils/url';
import useBreakpoint from '../utils/hooks/useBreakpoint';
import { toast } from 'react-toastify';
import axios from 'axios';
import { apiUrl } from '../utils/url';

function Login({ startAuthTimeout }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Invalid Username or Password!',
  );
  const usernameInputRef = useRef(null);
  const breakpoint = useBreakpoint();

  const navigate = useNavigate();

  const { setUser } = useUser();

  const login = async (e) => {
    e.preventDefault();

    const user = await jwtLogin(username, password);
    if (user) {
      console.log({ user });
      setUser(user);
      startAuthTimeout();
      navigate(localStorage.getItem('last-path') || '/dashboard');
    } else {
      displayError();
    }
  };

  const displayError = () => {
    setErrorOpen(true);
    setPassword('');
    setUsername('');
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  };

  const requestPasswordReset = async () => {
    if (!username) {
      setErrorOpen(true);
      setPassword('');
      setErrorMessage('Please enter a username to reset password.');
      return;
    }
    const toastId = toast.loading('Sending password reset email...');
    try {
      const response = await axios.post(
        `${apiUrl}auth/forgot-password-request`,
        {
          username,
        },
      );
      console.log(response);
      if (response.data.success) {
        toast.update(toastId, {
          render: 'Password reset email sent!',
          type: 'success',
          autoClose: 1500,
          isLoading: false,
        });
      } else {
        toast.update(toastId, {
          render: 'Error sending password reset email...',
          type: 'error',
          autoClose: 1500,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: 'Error sending password reset email...',
        type: 'error',
        autoClose: 1500,
        isLoading: false,
      });
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="5" className="d-flex justify-content-center">
          <img
            src={`${baseUrl}cp-logo-400-light.png`}
            width="200px"
            alt="Copy Pasta"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" md="5">
          <Alert
            className="shadow rounded"
            color="danger"
            isOpen={errorOpen}
            toggle={() => setErrorOpen(false)}
          >
            <FontAwesomeIcon icon={faWarning} />{' '}
            <span className="ms-2">{errorMessage}</span>
          </Alert>
          <Card
            className={`${
              breakpoint !== 'xs' && breakpoint !== 'sm' && 'shadow'
            } rounded mt-3 border-0`}
          >
            <CardBody>
              <Form onSubmit={login}>
                <FormGroup>
                  <Label for="username">Username</Label>
                  <InputGroup>
                    <InputGroupText>
                      <FontAwesomeIcon icon={faUser} />
                    </InputGroupText>
                    <Input
                      innerRef={usernameInputRef}
                      type="text"
                      name="username"
                      id="username"
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                      placeholder="Username"
                      autoComplete="username"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <InputGroup>
                    <InputGroupText>
                      <FontAwesomeIcon icon={faLock} />
                    </InputGroupText>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                  </InputGroup>
                </FormGroup>
                <Button block type="submit" color="cpblue-500">
                  Login
                </Button>
              </Form>
              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  Forgot your password?{' '}
                  <Button
                    color="link"
                    className="p-0"
                    onClick={requestPasswordReset}
                  >
                    Reset Password
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
