import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, FormFeedback, FormGroup, Label } from 'reactstrap';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

function TypeaheadWithCustomInput({
  label,
  fullWidth = false,
  customOnChange = null,
  objects = false,
  valueKey = null,
  options = [],
  required = false,
  ...props
}) {
  const [{ onChange, value, ...field }, meta, { setValue }] = useField(props);
  const [internalOptions, setInternalOptions] = useState(options);

  useEffect(() => {
    // Let's see if the value is in the options
    if (value && value !== '') {
      let found = options.filter((option) => option[valueKey] === value)[0];
      if (!found) {
        // If not, let's add it to the options
        let customOption = objects ? {} : value;
        if (objects) {
          customOption[valueKey] = value;
          customOption[props.labelKey || 'label'] = value;
        }
        setInternalOptions([...options, customOption]);
      }
    }
  }, [options, value]);

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>
          {required && (
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
          )}
          {label}
        </Label>
        <Typeahead
          allowNew={true}
          filterBy={(option, props) => {
            if (props.selected && props.selected.length) {
              return true;
            } else {
              if (objects) {
                return (
                  option[props.labelKey]
                    .toLowerCase()
                    .indexOf(props.text.toLowerCase()) !== -1
                );
              } else {
                return (
                  option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                );
              }
            }
          }}
          inputProps={{ id: props.id, name: props.name }}
          isInvalid={meta.touched && meta.error ? true : false}
          className={meta.touched && meta.error ? 'is-invalid' : ''}
          options={internalOptions}
          onChange={(selected) => {
            let customOption = undefined;
            if (selected.length > 0 && selected[0].customOption) {
              // If the selected option is a custom option, let's properly format it
              customOption = objects ? {} : selected[0].key;
              if (objects) {
                customOption[valueKey] = selected[0].key;
                customOption[props.labelKey || 'label'] = selected[0].key;
              }
            }
            if (objects && valueKey) {
              if (customOnChange) {
                customOnChange(customOption || selected[0] || '');
              } else {
                setValue(
                  customOption
                    ? customOption[valueKey]
                    : selected.map((option) => option[valueKey])[0] || '',
                  true,
                );
              }
            } else {
              if (customOnChange) {
                customOnChange(customOption || selected[0] || '');
              } else {
                setValue(customOption || selected[0] || '', true);
              }
            }
          }}
          selected={
            objects && valueKey
              ? internalOptions.filter((option) => value === option[valueKey])
              : value.length > 0
              ? [value]
              : []
          }
          {...field}
          {...props}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default TypeaheadWithCustomInput;
