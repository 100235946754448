import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
);

const CountsByDateBarChart = ({ dates, label }) => {
  const countsByDate = dates.reduce((acc, date) => {
    acc[date] = acc[date] ? acc[date] + 1 : 1;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(countsByDate).map((date) => {
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      };
      return new Date(date).toLocaleDateString('en-US', options);
    }),
    datasets: [
      {
        label: label,
        data: Object.values(countsByDate),
        backgroundColor: '#07a6f5',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    responsive: true,
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
            modifierKey: 'shift',
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
  // return <div>Chart</div>;
};

export default CountsByDateBarChart;
