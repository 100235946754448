import React, { useState } from 'react';
import {
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from 'reactstrap';
import {
  FormCard,
  TypeaheadSingleInput,
  BootstrapTextInput,
} from '../components/forms';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkSimple,
  faCheck,
  faCopy,
} from '@fortawesome/pro-duotone-svg-icons';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import { toast } from 'react-toastify';
import { getJwt } from '../utils/jwt';
import axios from 'axios';
import { apiUrl } from '../utils/url';
import ResponsiveIconSubmitButton from '../components/ResponsiveIconSubmitButton';

const CreateLinkSchema = Yup.object().shape({
  destination: Yup.string()
    .url('Destination must be a valid URL')
    .required('Destination is required'),
  domainId: Yup.string().required('Domain is required'),
});

function Rebrandly() {
  const [link, setLink] = useState(null);
  // Whether Content Was Copied With Button
  const [copied, setCopied] = useState(false);
  const { data: rebrandlyDomains = [] } = useAllQuery('rebrandly-domains');

  const handleSubmit = async (values, { setSubmitting }) => {
    const toastId = toast.loading('Creating short link...');

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getJwt()}`,
      },
    };

    axios
      .post(`${apiUrl}rebrandly/links/new`, values, axiosConfig)
      .then((response) => {
        setLink(`https://${response.data.shortUrl}`);
        toast.update(toastId, {
          render: 'Created short link!',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
        setSubmitting(false);
      })
      .catch((error) => {
        console.error(error);
        toast.update(toastId, {
          render: 'Error creating short link...',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
        setSubmitting(false);
      });
  };

  // Create a text area with our content and then copy it to clipboard
  // and then remove text area
  const copyContent = () => {
    let textArea = document.createElement('textarea');
    textArea.value = link;
    textArea.setAttribute('readonly', '');
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
  };

  return (
    <Container fluid>
      <FormCard
        breadcrumbLink="/dashboard"
        breadcrumbLabel="Dashboard"
        breadcrumbItem="Rebrandly Links"
      >
        {link && (
          <Row>
            <Col>
              <FormGroup>
                <Label for="generatedLink">Rebrandly Link</Label>
                <InputGroup>
                  <Input type="text" value={link} />
                  <Button
                    color={copied ? 'cpblue-300' : 'cpblue-500'}
                    onClick={copyContent}
                  >
                    <FontAwesomeIcon icon={copied ? faCheck : faCopy} />{' '}
                    {copied ? 'Copied' : 'Copy'}
                  </Button>
                </InputGroup>
              </FormGroup>
              <hr />
            </Col>
          </Row>
        )}
        <Formik
          initialValues={{
            destination: '',
            domainId: '',
          }}
          validationSchema={CreateLinkSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => (
            <Form>
              <Row>
                <BootstrapTextInput
                  fullWidth
                  label="Destination"
                  id="destination"
                  name="destination"
                  placeholder="Destination"
                />
                <TypeaheadSingleInput
                  fullWidth
                  label="Rebrandly Domain"
                  id="domainId"
                  name="domainId"
                  placeholder="Rebrandly Domain"
                  options={rebrandlyDomains}
                  labelKey="fullName"
                  valueKey="id"
                  objects
                />
              </Row>
              <ResponsiveIconSubmitButton
                disabled={isSubmitting || !isValid}
                icon={faLinkSimple}
              >
                Get Link
              </ResponsiveIconSubmitButton>
            </Form>
          )}
        </Formik>
      </FormCard>
    </Container>
  );
}

export default Rebrandly;
