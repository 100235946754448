export const replaceBetween = (string, start, end, what) => {
  return string.substring(0, start) + what + string.substring(end);
};

export const capitalizeWords = (str) => {
  let words = str.split(/(?=[A-Z])/); // split at capital letters
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1); // capitalize first letter
  }
  return words.join(' '); // join words back together
};
