import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, FormFeedback, FormGroup, Label } from 'reactstrap';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

function TypeaheadMultiInput({
  label,
  fullWidth = false,
  objects = false,
  valueKey = null,
  options = [],
  required = false,
  ...props
}) {
  const [{ onChange, value, ...field }, meta, { setValue }] = useField(props);

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>
          {required && (
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
          )}
          {label}
        </Label>
        <Typeahead
          inputProps={{ id: props.id, name: props.name }}
          multiple
          isInvalid={meta.touched && meta.error ? true : false}
          className={meta.touched && meta.error ? 'is-invalid' : ''}
          options={options}
          onChange={(selected) => {
            if (objects && valueKey) {
              setValue(
                selected.map((option) => option[valueKey]),
                true,
              );
            } else {
              setValue(selected, true);
            }
          }}
          selected={
            objects && valueKey
              ? options.filter((option) => value.includes(option[valueKey]))
              : value
              ? Array.isArray(value)
                ? value
                : [value]
              : []
          }
          {...field}
          {...props}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default TypeaheadMultiInput;
