export const schema = {
  Symbol: {
    prop: 'symbol',
    type: String,
    required: true,
  },
  Date: {
    prop: 'date',
    type: Date,
    required: true,
  },
  Gain: {
    prop: 'gain',
    type: Number,
    required: true,
  },
  When: {
    prop: 'when',
    type: String,
    required: true,
  },
};

const template = `<tr><td bgcolor="$bgColor" align="center" valign="top"><table width="100%" align="center" cellspacing="0" cellpadding="0" border="0"><tr><td width="33%" align="center" valign="middle" style="padding: 10px 0 10px 0;"><p style="margin: 0;"><strong>$symbol</strong></p></td><td width="33%" align="center" valign="middle" style="padding: 10px 0 10px 0;"><p style="margin: 0;"><strong>$date</strong></p></td><td width="33%" align="center" valign="middle" style="padding: 10px 0 10px 0;"><p style="margin: 0; color: #0CAB03;"><strong>$gain%<br />($when)</strong></p></td></tr></table></td></tr>`;

export const build = (rows) => {
  let product = '';
  rows.forEach((row, i) => {
    let tempRow = template;
    let bgColor = i % 2 === 0 ? '#CCE4FC' : '#E6F1FA';
    tempRow = tempRow.replace('$bgColor', bgColor);
    tempRow = tempRow.replace('$symbol', row.symbol);
    let month = row.date.getMonth() + 1;
    let day = row.date.getDate();
    let year = row.date.getFullYear();
    let date = `${month}/${day}/${year}`;
    tempRow = tempRow.replace('$date', date);
    tempRow = tempRow.replace('$gain', row.gain);
    tempRow = tempRow.replace('$when', row.when);
    product += `${tempRow}\n`;
  });

  return product;
};
