import React from 'react';
import { BLOCK_TYPE, ENTITY_TYPE } from 'draftail';

export const importerConfig = {
  htmlToEntity: (nodeName, node, createEntity) => {
    // a tags will become LINK entities, marked as mutable, with only the URL as data.
    if (nodeName === 'a') {
      return createEntity(ENTITY_TYPE.LINK, 'MUTABLE', {
        url: node.dataset.url,
      });
    }

    if (nodeName === 'img') {
      return createEntity(ENTITY_TYPE.IMAGE, 'IMMUTABLE', {
        src: node.src,
        alt: node.alt,
        link: node.dataset.link,
      });
    }

    if (nodeName === 'hr') {
      return createEntity(ENTITY_TYPE.HORIZONTAL_RULE, 'IMMUTABLE', {});
    }

    return null;
  },
  htmlToBlock: (nodeName) => {
    if (nodeName === 'hr' || nodeName === 'img') {
      // atomic blocks are how Draft.js structures block-level entities.
      return BLOCK_TYPE.ATOMIC;
    }

    return null;
  },
};

export const exporterConfig = {
  blockToHTML: (block) => {
    if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
      return {
        start: '<blockquote style="margin-bottom: 0;"><p>',
        end: '</p></blockquote>',
      };
    }

    // Discard atomic blocks, as they get converted based on their entity.
    if (block.type === BLOCK_TYPE.ATOMIC) {
      return {
        start: '',
        end: '',
      };
    }

    return null;
  },

  entityToHTML: (entity, originalText) => {
    if (entity.type === ENTITY_TYPE.LINK) {
      return (
        <a
          href={entity.data.url}
          data-url={entity.data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {originalText}
        </a>
      );
    }
    if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
      return <hr />;
    }

    if (entity.type === ENTITY_TYPE.IMAGE) {
      if (entity.data.link !== '') {
        return (
          <img
            src={entity.data.src}
            alt={entity.data.alt}
            className="aligncenter img-fluid img-responsive"
            data-link={entity.data.link}
            data-inserted="true"
          />
        );
      } else {
        return (
          <img
            src={entity.data.src}
            alt={entity.data.alt}
            className="aligncenter img-fluid img-responsive"
            data-inserted="true"
          />
        );
      }
    }

    return originalText;
  },
};
