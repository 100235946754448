import React from 'react';
import { Col, FormGroup, FormFeedback, Label } from 'reactstrap';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { useField } from 'formik';

function AceCodeEditorInput({ label, fullWidth = true, ...props }) {
  const [{ onChange, onBlur, ...field }, meta, { setValue, setTouched }] =
    useField(props);

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>{label}</Label>
        <AceEditor
          fontSize={16}
          showPrintMargin={false}
          wrapEnabled={true}
          width="100%"
          mode="html"
          theme={'tomorrow'}
          editorProps={{ $blockScrolling: true }}
          onChange={(html) => setValue(html, true)}
          setOptions={{
            indentedSoftWrap: false,
            printMarginColumn: 90,
            useWorker: false,
          }}
          {...props}
          {...field}
          onBlur={() => setTouched(true, true)}
        />
        <div
          className={`${meta.touched && meta.error ? 'is-invalid' : ''} d-none`}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default AceCodeEditorInput;
