import React from 'react';
import {
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from 'reactstrap';

function PaginationBar({
  totalPages,
  currentPage,
  setPage,
  perPage,
  setPerPage,
}) {
  const populatePaginationItems = () => {
    const items = [
      <PaginationItem key="first" disabled={currentPage === 1}>
        <PaginationLink first onClick={() => setPage(1)} />
      </PaginationItem>,
      <PaginationItem key="prev" disabled={currentPage === 1}>
        <PaginationLink previous onClick={() => setPage(currentPage - 1)} />
      </PaginationItem>,
    ];

    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink
            className={`${i === currentPage && 'text-bg-cpblue-500'}`}
            onClick={() => setPage(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }

    items.push(
      <PaginationItem key="next" disabled={currentPage === totalPages}>
        <PaginationLink next onClick={() => setPage(currentPage + 1)} />
      </PaginationItem>,
      <PaginationItem key="last" disabled={currentPage === totalPages}>
        <PaginationLink last onClick={() => setPage(totalPages)} />
      </PaginationItem>,
    );

    return items;
  };

  return (
    <Row>
      <Col>
        <Pagination>{populatePaginationItems()}</Pagination>
      </Col>
      <Col>
        <Input
          type="select"
          className="w-auto"
          onChange={(e) => setPerPage(parseInt(e.target.value))}
          value={perPage}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </Input>
      </Col>
    </Row>
  );
}

export default PaginationBar;
