import React, { useState, useRef } from 'react';
import {
  Progress,
  Col,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
  Button,
  FormFeedback,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import * as jwt from '../../utils/jwt';
import { apiUrl } from '../../utils/url';
import { rewriteS3ImageUrls } from '../../utils/rewriteUrls';
import axios from 'axios';
import { useField } from 'formik';

function ImageUploadInputFormik({
  label,
  fullWidth = false,
  wordPress = null,
  required = false,
  children,
  ...props
}) {
  const [{ value, ...field }, meta, { setValue }] = useField(props);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const displayValue = wordPress ? value.guid?.rendered || '' : value || '';

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDraggingOver(false);
    const file = event.dataTransfer.files[0];
    if (file.type.match(/^image\//)) {
      console.log('Dropped file:', file);
      setLoading(true);
      const toastId = toast.loading('Uploading image...');
      uploadImageFile(file, toastId);
    }
  };

  const clearField = () => {
    setValue('', true);
  };

  const clickFileInput = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const imageUploadHandler = (e) => {
    setLoading(true);
    const toastId = toast.loading('Uploading image...');

    // Get our file
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      uploadImageFile(file, toastId);
    }
  };

  const uploadImageFile = async (file, toastId) => {
    // create and append some form data
    const formData = new FormData();
    if (wordPress) {
      formData.append('file', file);
    } else {
      formData.append('image', file);
      formData.append('bucketName', 'email.oxfordclub.com');
      formData.append('path', 'copypasta/images/');
    }

    const endpoint = wordPress
      ? `${apiUrl}wordpress/${wordPress}/media`
      : `${apiUrl}s3-images/upload`;

    // Get our JWT and config our options
    const myJwt = jwt.getJwt();
    if (myJwt) {
      const options = {
        method: 'POST',
        url: endpoint,
        headers: {
          Authorization: `Bearer ${myJwt}`,
        },
        data: formData,
      };

      try {
        const response = await axios.request(options);
        setValue(
          wordPress
            ? response.data
            : rewriteS3ImageUrls(response.data.Location),
        );
        toast.update(toastId, {
          render: 'Image uploaded!',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.update(toastId, {
          render: 'Failed to upload image...',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
        setLoading(false);
      }
    } else {
      console.error(new Error('Unable to authenticate...'));
      toast.update(toastId, {
        render: 'Must be logged in to upload image...',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
      setLoading(false);
    }
  };

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>
          {required && (
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
          )}
          {label}
        </Label>
        {loading ? (
          <Progress animated color="cpblue-500" value={100} />
        ) : (
          <InputGroup
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={
              isDraggingOver
                ? 'border border-2 border-cpblue-500 shadow rounded'
                : ''
            }
          >
            {displayValue.length > 0 && (
              <InputGroupText className="p-0">
                <img
                  className="rounded"
                  alt={`${props.name} preview`}
                  width="36px"
                  height="36px"
                  src={displayValue}
                />
              </InputGroupText>
            )}
            <Input
              disabled={wordPress ? true : false}
              value={displayValue}
              {...field}
              {...props}
            />
            <Button
              color={displayValue.length === 0 ? 'cpblue-500' : 'cpblue-300'}
              onClick={displayValue.length === 0 ? clickFileInput : clearField}
            >
              <FontAwesomeIcon
                icon={displayValue.length === 0 ? faUpload : faXmark}
              />
            </Button>
          </InputGroup>
        )}
        {children}
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
      <input
        ref={fileInputRef}
        type="file"
        id="image-upload"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={imageUploadHandler}
      />
    </Col>
  );
}

export default ImageUploadInputFormik;
