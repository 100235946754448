import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  SaveButton,
  FormCard,
  TypeaheadMultiInput,
} from '../../components/forms';
import { _ROLE } from '../../utils/empties';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import { useSingleQuery } from '../../utils/hooks/reactQuery/queries';

const RoleSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  permissions: Yup.array().of(Yup.string()),
});

function Role() {
  const { id } = useParams();
  const {
    data = _ROLE,
    isLoading,
    isError,
    error,
  } = useSingleQuery('role', id);
  const { update, add } = useItemMutation('role');

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving role...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved role!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save role...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved role!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save role...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/roles"
          breadcrumbLabel="Roles"
          breadcrumbItem={data.name.length > 0 ? data.name : 'New Role'}
        >
          <Formik
            initialValues={data}
            validationSchema={RoleSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadMultiInput
                    label="Permissions"
                    id="permissions"
                    name="permissions"
                    placeholder="Permissions"
                    options={['Edit', 'Read', 'Delete']}
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default Role;
