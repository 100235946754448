import React, { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useSearchParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faCopy } from '@fortawesome/pro-duotone-svg-icons';
import {
  faChevronDown,
  faChevronUp,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import UserAvatar from '../../components/UserAvatar';
import NewItemButton from '../../components/NewItemButton';
import PaginationBar from '../../components/PaginationBar';
import { useConfirmActionModal } from '../../utils/contexts/confimActionModalContext';
import {
  usePagination,
  usePrefetchAll,
} from '../../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';

function UserGroups() {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [searchValue, setSearchValue] = useState('');
  const [queryState, setQueryState] = useState({
    query: '',
    sort: 'modifiedAt',
    order: -1,
    page: 1,
    limit: 10,
    tags: [],
  });
  const { data, isLoading, isError, error } = usePagination(
    'user-groups',
    queryState,
  );
  const { setConfirmActionModal } = useConfirmActionModal();
  const { add, _delete } = useItemMutation('user-group');
  // PREFETCH
  usePrefetchAll('users');

  useEffect(() => {
    if (searchParams) {
      const searchParamsObj = Object.fromEntries(searchParams.entries());
      setQueryState((old) => ({
        ...old,
        ...searchParamsObj,
        order: searchParamsObj.order
          ? parseInt(searchParamsObj.order)
          : old.order,
        page: searchParamsObj.page ? parseInt(searchParamsObj.page) : old.page,
        limit: searchParamsObj.limit
          ? parseInt(searchParamsObj.limit)
          : old.limit,
        tags: searchParamsObj.tags ? searchParamsObj.tags.split(',') : old.tags,
      }));
      setSearchValue(searchParamsObj.query || '');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchParams({ ...queryState, tags: queryState.tags.join(',') });
  }, [queryState, setSearchParams]);

  // method to update our search value via debounce
  const handleQueryChange = (value) => {
    console.log('setting query');
    setQueryState((old) => ({ ...old, query: value }));
  };

  // For debouncing our search field
  const debouncedResults = useMemo(() => {
    return debounce(handleQueryChange, 300);
  }, []);

  // on unmount we want to clean up our debounce
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  // When the query changes we want to update our search
  useEffect(() => {
    console.log('search value changed');
    debouncedResults(searchValue);
  }, [searchValue, debouncedResults]);

  // Set the field to sort by
  const updateSortBy = (field) => {
    // Are we clicking the same field?
    // if so we will reverse the sorting
    let clone = { ...queryState };
    if (queryState.sort === field) {
      clone.order = queryState.order * -1;
    }
    setQueryState({
      ...clone,
      sort: field,
    });
  };

  const popUserGroupsTable = (userGroups) => {
    return userGroups.map((userGroup) => (
      <tr key={userGroup._id}>
        <td className="align-middle">{userGroup.name}</td>
        <td className="align-middle">
          {userGroup.modifiedBy ? (
            <div className="d-flex align-items-center">
              <UserAvatar user={userGroup.modifiedBy} />
              <div className="ms-3">
                <p className="fw-bold mb-1">{`${userGroup.modifiedBy.firstName} ${userGroup.modifiedBy.lastName}`}</p>
                <p className="text-muted mb-0">
                  {userGroup.modifiedBy.username}
                </p>
              </div>
            </div>
          ) : (
            'Unknown user...'
          )}
        </td>
        <td className="align-middle">
          {new Date(userGroup.modifiedAt).toLocaleString([], {
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </td>
        <td className="align-middle">
          <Link to={userGroup._id} className="text-decoration-none">
            <Button
              color="cpblue-500"
              className="rounded-circle d-block mx-auto"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </Link>
        </td>
        <td className="align-middle">
          <Button
            color="cpblue-200"
            className="rounded-circle d-block mx-auto"
            onClick={() => {
              let clone = { ...userGroup };
              delete clone._id;
              delete clone.broadcastTemplates;
              clone.name = `COPY - ${clone.name}`;
              add.mutate(clone, {
                onError: (error) => {
                  console.error(error);
                },
              });
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </td>
        <td className="align-middle">
          <Button
            color="cpred-500"
            className="rounded-circle d-block mx-auto"
            onClick={() =>
              setConfirmActionModal({
                open: true,
                title: 'Confirm Delete?',
                severity: 'warning',
                message: `Are you sure you'd like to delete ${userGroup.name}?`,
                onClose: (confirmed) => {
                  if (confirmed) {
                    const toastId = toast.loading(
                      `Deleting ${userGroup.name}...`,
                    );
                    _delete.mutate(userGroup._id, {
                      onSuccess: () => {
                        console.log('Successfully deleted user group!');
                        toast.update(toastId, {
                          render: `Deleted ${userGroup.name}!`,
                          type: 'success',
                          isLoading: false,
                          autoClose: 1500,
                        });
                      },
                      onError: (error) => {
                        console.error(error);
                        toast.update(toastId, {
                          render: 'Error deleting...',
                          type: 'error',
                          isLoading: false,
                          autoClose: 1500,
                        });
                      },
                    });
                  }
                },
              })
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </td>
      </tr>
    ));
  };

  const popFillerRows = ({ docs, limit }) => {
    const rows = [];

    for (let i = 0; i < limit - docs.length; i++) {
      rows.push(
        <tr key={i}>
          <td className="align-middle" style={{ height: '58px' }}>
            &nbsp;
          </td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
        </tr>,
      );
    }

    return rows;
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>User Groups</h1>
          <p>Create, Delete, and Update User Groups here.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <NewItemButton text="Add User Group" />
          <InputGroup className="w-25 float-end">
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
            <Input
              type="text"
              name="search"
              id="search"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          {isLoading ? (
            <h5>Loading...</h5>
          ) : isError ? (
            <h5>Error: {error.message}</h5>
          ) : (
            <>
              <Card className="rounded shadow border-0">
                <CardBody>
                  <Table borderless striped responsive>
                    <thead>
                      <tr>
                        <th
                          style={{ cursor: 'pointer' }}
                          onClick={() => updateSortBy('name')}
                        >
                          Name
                          {queryState.sort === 'name' && (
                            <>
                              {' '}
                              <FontAwesomeIcon
                                icon={
                                  queryState.order === 1
                                    ? faChevronUp
                                    : faChevronDown
                                }
                                className="float-end"
                              />
                            </>
                          )}
                        </th>
                        <th
                          style={{ cursor: 'pointer' }}
                          onClick={() => updateSortBy('modifiedBy.firstName')}
                        >
                          Modified By
                          {queryState.sort === 'modifiedBy.firstName' && (
                            <>
                              {' '}
                              <FontAwesomeIcon
                                icon={
                                  queryState.order === 1
                                    ? faChevronUp
                                    : faChevronDown
                                }
                                className="float-end"
                              />
                            </>
                          )}
                        </th>
                        <th
                          style={{ cursor: 'pointer' }}
                          onClick={() => updateSortBy('modifiedAt')}
                        >
                          Modified At
                          {queryState.sort === 'modifiedAt' && (
                            <>
                              {' '}
                              <FontAwesomeIcon
                                icon={
                                  queryState.order === 1
                                    ? faChevronUp
                                    : faChevronDown
                                }
                                className="float-end"
                              />
                            </>
                          )}
                        </th>
                        <th className="text-center">Edit</th>
                        <th className="text-center">Clone</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {popUserGroupsTable(data.docs)}
                      {popFillerRows(data)}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              <Row className="mt-5">
                <Col className="d-flex justify-content-center">
                  <PaginationBar
                    currentPage={data.page}
                    totalPages={data.totalPages}
                    setPage={(newPage) =>
                      setQueryState((old) => ({ ...old, page: newPage }))
                    }
                    perPage={data.limit}
                    setPerPage={(newPerPage) =>
                      setQueryState((old) => ({ ...old, limit: newPerPage }))
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default UserGroups;
