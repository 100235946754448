export const getFormattedDateTimeString = (
  hyphenated = false,
  fromDate = null,
) => {
  // Do Stuff to Get a Date and Time Formatted: YYYYMMDDTHH:MM
  const now = fromDate || new Date();
  const year = now.getFullYear();
  const month =
    now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
  const date = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
  const hours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
  const minutes =
    now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();

  return hyphenated
    ? `${year}-${month}-${date}T${hours}:${minutes}`
    : `${year}${month}${date}T${hours}:${minutes}`;
};

export const getDefaultBroadcastName = (code) => {
  return `${getFormattedDateTimeString()}_${code}`;
};
