import React from 'react';
import {
  DraftailEditor,
  BLOCK_TYPE,
  INLINE_STYLE,
  ENTITY_TYPE,
} from 'draftail';
import { convertFromRaw, convertToRaw } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { importerConfig, exporterConfig } from './DraftConvertConfig';
import LinkSource from './LinkSource';
import ImageSource from './ImageSource';
import createHandlePastedText from './HandlePasted';
import { entitiesToCharacters } from '../../js/Email';
import { formatHtml } from '../../js/HTML';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold,
  faH2,
  faImage,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faMinus,
  faQuoteLeft,
  faUnderline,
} from '@fortawesome/pro-regular-svg-icons';

function Draftail({ initialHtml, setHtml, showTopToolbar, ...props }) {
  // Convert from HTML to Raw
  const fromHtml = (html) =>
    convertToRaw(convertFromHTML(importerConfig)(html));
  // Convert from Raw to HTML
  const toHtml = (raw) =>
    raw
      ? entitiesToCharacters(
          formatHtml(convertToHTML(exporterConfig)(convertFromRaw(raw))),
        )
      : '';

  // LINK Decorator
  const Link = (props) => {
    const { contentState, entityKey } = props;
    const { url } = contentState.getEntity(entityKey).getData();
    return (
      <a className="link" href={url}>
        {props.children}
      </a>
    );
  };

  // IMAGE Decorator
  const Image = (props) => {
    const { blockProps } = props;
    const { entity } = blockProps;
    const { src, alt, link } = entity.getData();

    return (
      <img className="ImageBlock" src={src} alt={alt} width="550" link={link} />
    );
  };

  // On Editor Save
  const onSave = (content) => {
    setHtml(toHtml(content));
  };

  // Create Hanlde Pasted Text Plugin
  const handlePastedText = createHandlePastedText();

  return (
    <DraftailEditor
      rawContentState={fromHtml(initialHtml)}
      onSave={onSave}
      enableHorizontalRule={{
        description: 'Horizontal rule',
        icon: <FontAwesomeIcon icon={faMinus} />,
      }}
      blockTypes={[
        {
          icon: <FontAwesomeIcon icon={faListUl} />,
          type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
        },
        {
          icon: <FontAwesomeIcon icon={faListOl} />,
          type: BLOCK_TYPE.ORDERED_LIST_ITEM,
        },
        {
          icon: <FontAwesomeIcon icon={faQuoteLeft} />,
          type: BLOCK_TYPE.BLOCKQUOTE,
        },
        {
          icon: <FontAwesomeIcon icon={faH2} />,
          type: BLOCK_TYPE.HEADER_TWO,
        },
      ]}
      inlineStyles={[
        { icon: <FontAwesomeIcon icon={faBold} />, type: INLINE_STYLE.BOLD },
        {
          icon: <FontAwesomeIcon icon={faItalic} />,
          type: INLINE_STYLE.ITALIC,
        },
        {
          icon: <FontAwesomeIcon icon={faUnderline} />,
          type: INLINE_STYLE.UNDERLINE,
        },
      ]}
      entityTypes={[
        {
          type: ENTITY_TYPE.LINK,
          icon: <FontAwesomeIcon icon={faLink} />,
          source: LinkSource,
          decorator: Link,
        },
        {
          type: ENTITY_TYPE.IMAGE,
          icon: <FontAwesomeIcon icon={faImage} />,
          source: ImageSource,
          block: Image,
        },
      ]}
      plugins={[handlePastedText]}
      stripPastedStyles={false}
      topToolbar={showTopToolbar ? undefined : null}
      {...props}
    />
  );
}

export default Draftail;
