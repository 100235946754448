import axios from 'axios';
import { apiUrl } from './url';

export const setJwt = (token) => {
  localStorage.setItem('authToken', token);
};

export const getJwt = () => {
  return localStorage.getItem('authToken');
};

export const getJwtExpiration = () => {
  const jwt = getJwt();

  if (!jwt) {
    return 0;
  }
  const parsed = JSON.parse(atob(jwt.split('.')[1]));
  return parsed.exp * 1000;
};

export const getJwtUserId = () => {
  const jwt = getJwt();

  if (!jwt) {
    return null;
  }
  const parsed = JSON.parse(atob(jwt.split('.')[1]));
  return parsed._id;
};

export const getUser = async () => {
  const userId = getJwtUserId();
  if (!userId) {
    return null;
  } else {
    const [error, user] = await get(
      `${apiUrl}users/${userId}?populate=roles|userGroups`,
      { Authorization: `Bearer ${getJwt()}` },
    );
    if (!error) {
      return user;
    } else {
      console.error(error);
      return null;
    }
  }
};

export const isJwtExpired = () => {
  const jwtExpiration = getJwtExpiration();

  if (!jwtExpiration) {
    return null;
  }
  return Date.now() > jwtExpiration;
};

export const login = async (username, password) => {
  const [error, response] = await post(
    `${apiUrl}auth/login`,
    { Authorization: '' },
    { username, password },
  );
  if (!error) {
    setJwt(response.access_token);
    return await getUser();
  } else {
    console.error(error);
    return null;
  }
};

export const refreshJwt = async () => {
  const jwt = getJwt();
  const [error, response] = await post(`${apiUrl}auth/refresh-token`, {
    Authorization: `Bearer ${jwt}`,
  });
  if (!error) {
    setJwt(response.access_token);
    return true;
  } else {
    console.error(error);
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem('authToken');
};

// HELPER FUNCTIONS
//-----------------------------------------

// Axios GET
const get = async (url, headers) => {
  const options = {
    method: 'GET',
    url: url,
    headers: headers,
  };

  try {
    const response = await axios.request(options);
    return [null, response.data];
  } catch (error) {
    return [error, null];
  }
};

// Axios POST
const post = async (url, headers, data) => {
  const options = {
    method: 'POST',
    url,
    headers,
    data,
  };

  try {
    const response = await axios.request(options);
    return [null, response.data];
  } catch (error) {
    return [error, null];
  }
};
