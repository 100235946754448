import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import {
  BootstrapTextInput,
  SaveButton,
  TypeaheadMultiInput,
  TypeaheadTagsInput,
  FormCard,
  BootstrapToggleInput,
  TypeaheadSingleInput,
} from '../../components/forms';
import { _BROADCAST_TEMPLATE } from '../../utils/empties';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import {
  useAllQuery,
  useExternalFetchQuery,
  useSingleQuery,
} from '../../utils/hooks/reactQuery/queries';
import useBreakpoint from '../../utils/hooks/useBreakpoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-duotone-svg-icons';

const BroadcastTemplateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tags: Yup.array().of(Yup.string()),
  itemCode: Yup.string().required('Item Code is required'),
  userGroups: Yup.array().of(Yup.string()),
  externalFetchTemplates: Yup.array().of(Yup.string()),
  inputFields: Yup.array().of(Yup.string()),
  defaultOptions: Yup.array().of(
    Yup.object().shape({ key: Yup.string(), value: Yup.mixed() }),
  ),
  defaultContent: Yup.string(),
  emailEnabled: Yup.boolean(),
  smsEnabled: Yup.boolean(),
  webEnabled: Yup.boolean(),
  webDelay: Yup.boolean(),
  keywordsEnabled: Yup.boolean(),
  testEnabled: Yup.boolean(),
  emailBroadcasts: Yup.array().of(Yup.string()),
  emailTemplate: Yup.string().nullable(),
  smsBroadcasts: Yup.array().of(
    Yup.object().shape({
      config: Yup.string().required('SMS Config required'),
      template: Yup.string().required('SMS Template required'),
    }),
  ),
  webPosts: Yup.array().of(Yup.string()),
});

function BroadcastTemplate() {
  const { id } = useParams();
  const {
    data = _BROADCAST_TEMPLATE,
    isLoading,
    isError,
    error,
  } = useSingleQuery('broadcast-template', id);
  const { update, add } = useItemMutation('broadcast-template');
  const { data: emailConfigs = [] } = useAllQuery('bsft-email-configs');
  const { data: smsConfigs = [] } = useAllQuery('bsft-sms-configs');
  const { data: wpConfigs = [] } = useAllQuery('wp-configs');
  const { data: templateFiles = [] } = useAllQuery('template-files');
  const { data: inputFields = [] } = useAllQuery('input-fields');
  const { data: userGroups = [] } = useAllQuery('user-groups');
  const { data: externalFetchTemplates = [] } = useAllQuery(
    'bsft-external-fetch-templates',
  );
  const fallback = { Objectives: [], Psychologies: [], Sectors: [] };
  const { data: wordPressTaxonomies = fallback } = useAllQuery(
    'wp-global-taxonomies',
  );
  const breakpoint = useBreakpoint();

  const getDefaultValueIndex = (defaultOptions, value, incrementor = 0) => {
    const index = defaultOptions
      .map((option) => option.key)
      .indexOf(value.toLowerCase().replace(/\s/g, '-'));
    if (index < 0) {
      return defaultOptions.length + incrementor;
    }
    return index;
  };

  const renderDefaultOptions = (values, errors) => {
    // Grab selected input fields
    const selectedInputFields = inputFields.filter((field) =>
      values.inputFields.includes(field._id),
    );
    return (
      <FieldArray id="defaultOptions" name="defaultOptions">
        {() => (
          <>
            {selectedInputFields &&
              selectedInputFields.length > 0 &&
              selectedInputFields.map((field, index) => {
                const pos = getDefaultValueIndex(
                  values.defaultOptions,
                  field.name,
                );
                return (
                  <React.Fragment key={`default-option-${pos}`}>
                    <BootstrapTextInput
                      hide
                      key={`key-${pos}`}
                      label="Key"
                      id={`defaultOptions[${pos}].key`}
                      name={`defaultOptions[${pos}].key`}
                      placeholder="Key"
                      value={field.name.toLowerCase().replace(/\s/g, '-')}
                    />
                    <BootstrapTextInput
                      key={`value-${pos}`}
                      label={`${field.name} Default Value`}
                      id={`defaultOptions[${pos}].value`}
                      name={`defaultOptions[${pos}].value`}
                      placeholder={`${field.name} Default Value`}
                    />
                  </React.Fragment>
                );
              })}
            {values.webEnabled && (
              <>
                {/* OBJECTIVES */}
                <BootstrapTextInput
                  hide
                  key={`key-objectives`}
                  label="Key"
                  id={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-objectives',
                    0,
                  )}].key`}
                  name={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-objectives',
                    0,
                  )}].key`}
                  placeholder="Key"
                  value="wordpress-objectives"
                />
                <TypeaheadSingleInput
                  label="Objectives"
                  id={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-objectives',
                    0,
                  )}].value`}
                  name={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-objectives',
                    0,
                  )}].value`}
                  placeholder="Objectives"
                  options={wordPressTaxonomies.Objectives || []}
                />
                {/* PSYCHOLOGIES */}
                <BootstrapTextInput
                  hide
                  key={`key-psychologies`}
                  label="Key"
                  id={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-psychologies',
                    1,
                  )}].key`}
                  name={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-psychologies',
                    1,
                  )}].key`}
                  placeholder="Key"
                  value="wordpress-psychologies"
                />
                <TypeaheadSingleInput
                  label="Psychologies"
                  id={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-psychologies',
                    1,
                  )}].value`}
                  name={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-psychologies',
                    1,
                  )}].value`}
                  placeholder="Psychologies"
                  options={wordPressTaxonomies.Psychologies || []}
                />
                {/* SECTORS */}
                <BootstrapTextInput
                  hide
                  key={`key-sectors`}
                  label="Key"
                  id={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-sectors',
                    2,
                  )}].key`}
                  name={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-sectors',
                    2,
                  )}].key`}
                  placeholder="Key"
                  value="wordpress-sectors"
                />
                <TypeaheadMultiInput
                  label="Sectors"
                  id={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-sectors',
                    2,
                  )}].value`}
                  name={`defaultOptions[${getDefaultValueIndex(
                    values.defaultOptions,
                    'wordpress-sectors',
                    2,
                  )}].value`}
                  placeholder="Sectors"
                  options={wordPressTaxonomies.Sectors || []}
                />
              </>
            )}
            {typeof errors.defaultOptions === 'string' ? (
              <div className="small text-danger">
                <strong>{errors.defaultOptions}</strong>
              </div>
            ) : null}
          </>
        )}
      </FieldArray>
    );
  };

  const renderSmsBroadcasts = (values, errors, isSubmitting) => {
    return (
      <Row>
        <Col className="mb-3">
          <p className="mb-2 p-0">SMS Broadcasts</p>
          <FieldArray id="smsBroadcasts" name="smsBroadcasts">
            {({ push, remove }) => (
              <>
                {values.smsBroadcasts &&
                  values.smsBroadcasts.length > 0 &&
                  values.smsBroadcasts.map((broadcast, index) => (
                    <Col key={index} className="border rounded mb-3 p-3">
                      <Row>
                        <TypeaheadSingleInput
                          key={`config-${index}`}
                          label="Config"
                          id={`smsBroadcasts[${index}].config`}
                          name={`smsBroadcasts[${index}].config`}
                          placeholder="Config"
                          objects
                          labelKey="name"
                          valueKey="_id"
                          options={smsConfigs}
                        />
                        <TypeaheadSingleInput
                          key={`template-${index}`}
                          label="Template"
                          id={`smsBroadcasts[${index}].template`}
                          name={`smsBroadcasts[${index}].template`}
                          placeholder="Template"
                          objects
                          labelKey="name"
                          valueKey="_id"
                          options={templateFiles.filter(
                            (file) => file.type === 'SMS',
                          )}
                        />
                        <Col>
                          <Button
                            className={`${
                              breakpoint === 'xs' || breakpoint === 'sm'
                                ? 'w-100'
                                : 'float-end'
                            }`}
                            color="cpred-500"
                            key={`remove-${index}`}
                            disabled={isSubmitting}
                            onClick={() => remove(index)}
                          >
                            <FontAwesomeIcon icon={faTrash} />{' '}
                            <span className="ms-2">Remove</span>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                <Button
                  block
                  color="cpblue-500"
                  disabled={isSubmitting}
                  onClick={() => push({ config: '', template: '' })}
                >
                  <FontAwesomeIcon icon={faPlus} />{' '}
                  <span className="ms-2">Add</span>
                </Button>
              </>
            )}
          </FieldArray>
        </Col>
      </Row>
    );
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving broadcast template...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved broadcast template!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save broadcast template...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved broadcast template!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save broadcast template...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/broadcast-templates"
          breadcrumbLabel="Broadcast Templates"
          breadcrumbItem={
            data.name.length > 0 ? data.name : 'New Broadcast Template'
          }
        >
          <Formik
            initialValues={data}
            validationSchema={BroadcastTemplateSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isValid, isSubmitting, values, errors }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                  <BootstrapTextInput
                    label="Item Code"
                    id="itemCode"
                    name="itemCode"
                    placeholder="Item Code"
                  />
                  <TypeaheadMultiInput
                    label="User Groups"
                    id="userGroups"
                    name="userGroups"
                    placeholder="User Groups"
                    objects
                    labelKey="name"
                    valueKey="_id"
                    options={userGroups}
                  />
                  <TypeaheadMultiInput
                    label="External Fetch Templates"
                    id="externalFetchTemplates"
                    name="externalFetchTemplates"
                    placeholder="External Fetch Templates"
                    objects
                    labelKey="alias_name"
                    valueKey="uuid"
                    options={externalFetchTemplates}
                  />
                  <TypeaheadMultiInput
                    label="Input Fields"
                    id="inputFields"
                    name="inputFields"
                    placeholder="Input Fields"
                    objects
                    labelKey="name"
                    valueKey="_id"
                    options={inputFields}
                  />
                  {renderDefaultOptions(values, errors)}
                </Row>
                <Row>
                  <BootstrapTextInput
                    fullWidth
                    isTextArea
                    label="Default Content"
                    id="defaultContent"
                    name="defaultContent"
                    placeholder="Default Content"
                  />
                  <BootstrapToggleInput
                    label="Email"
                    id="emailEnabled"
                    name="emailEnabled"
                    labelChecked="Email Enabled."
                    labelUnchecked="Email Disabled."
                  />
                  <BootstrapToggleInput
                    label="SMS"
                    id="smsEnabled"
                    name="smsEnabled"
                    labelChecked="SMS Enabled."
                    labelUnchecked="SMS Disabled."
                  />
                  <BootstrapToggleInput
                    label="Web"
                    id="webEnabled"
                    name="webEnabled"
                    labelChecked="Web Enabled."
                    labelUnchecked="Web Disabled."
                  />
                  {values.webEnabled && (
                    <BootstrapToggleInput
                      label="Web Delay"
                      id="webDelay"
                      name="webDelay"
                      labelChecked="Web Delay."
                      labelUnchecked="No Web Delay."
                    />
                  )}
                  <BootstrapToggleInput
                    label="Keywords"
                    id="keywordsEnabled"
                    name="keywordsEnabled"
                    labelChecked="Keywords Enabled."
                    labelUnchecked="Keywords Disabled."
                  />
                  <BootstrapToggleInput
                    label="Test"
                    id="testEnabled"
                    name="testEnabled"
                    labelChecked="Test Enabled."
                    labelUnchecked="Test Disabled."
                  />
                </Row>
                <Row>
                  {values.emailEnabled && (
                    <>
                      <TypeaheadMultiInput
                        label="Email Broadcasts"
                        id="emailBroadcasts"
                        name="emailBroadcasts"
                        placeholder="Email Broadcasts"
                        objects
                        labelKey="name"
                        valueKey="_id"
                        options={emailConfigs}
                      />
                      <TypeaheadSingleInput
                        label="Email Template"
                        id="emailTemplate"
                        name="emailTemplate"
                        placeholder="Email Template"
                        objects
                        labelKey="name"
                        valueKey="_id"
                        options={templateFiles.filter(
                          (file) => file.type === 'Email',
                        )}
                      />
                    </>
                  )}
                  {values.smsEnabled &&
                    renderSmsBroadcasts(values, errors, isSubmitting)}
                  {values.webEnabled && (
                    <TypeaheadMultiInput
                      fullWidth
                      label="Web Posts"
                      id="webPosts"
                      name="webPosts"
                      placeholder="Web Posts"
                      objects
                      labelKey="name"
                      valueKey="_id"
                      options={wpConfigs}
                    />
                  )}
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default BroadcastTemplate;
