import React, { useState } from 'react';
import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  Row,
  Col,
  Button,
  ModalBody,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSms } from '@fortawesome/pro-regular-svg-icons';

function TestSmsModal({ open, onClose }) {
  const [mobileNumber, setMobileNumber] = useState('');

  return (
    <Modal isOpen={open} toggle={() => onClose([], 'cancel')}>
      <ModalHeader className="text-bg-cpblue-500">
        <FontAwesomeIcon icon={faSms} /> <span className="ms-2">SMS Test</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>Who should we send the test to?</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="mobile-number">Mobile Number</Label>
              <InputGroup>
                <InputGroupText>+1</InputGroupText>
                <Input
                  type="tel"
                  id="mobile-number"
                  name="mobile-number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="1234567890"
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <Button
              color="cpblue-500"
              block
              onClick={() => {
                onClose(mobileNumber, 'send');
              }}
            >
              Send
            </Button>
            <Button
              className="mt-2"
              color="cpblue-100"
              block
              onClick={() => {
                onClose(null, 'cancel');
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default TestSmsModal;
