export const build = (rows) => {
  let product = '';
  // Loop through Rows
  rows.forEach((row) => {
    // Start a Row
    let tempRow = '<tr>';
    row.forEach((col) => {
      // add a Col to the Row with the content
      tempRow += `\n<td>${col}</td>`;
    });
    // Close the Row
    tempRow += '\n</tr>';
    // Add Row to Product
    product += `${tempRow}\n`;
  });
  return product;
};
