import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Route, Routes } from 'react-router-dom';
import {
  getJwt,
  getUser,
  isJwtExpired,
  getJwtExpiration,
  logout,
  refreshJwt,
} from './utils/jwt';
import { useUser } from './utils/contexts/userContext';
import usePageVisibility from './utils/hooks/usePageVisibility';
import AuthExpirationModal from './components/AuthExpirationModal';
import DashboardLayout from './layouts/DashboardLayout';
import {
  Login,
  Role,
  Roles,
  User,
  Users,
  UserGroup,
  UserGroups,
  TemplateFile,
  TemplateFiles,
  InputField,
  InputFields,
  BsftSmsConfig,
  BsftSmsConfigs,
  BsftEmailConfig,
  BsftEmailConfigs,
  WpConfigs,
  WpConfig,
  BroadcastTemplates,
  BroadcastTemplate,
  Broadcast,
  Broadcasts,
  PlaceholderMapping,
  PlaceholderMappings,
  HeroCrop,
  PastaDocs,
  TotdMarketingPush,
  TableBuilder,
  Rebrandly,
  PasswordReset,
} from './pages';
import DashBoard from './pages/Dashboard';

function App() {
  const { user, setUser } = useUser();
  const isPageVisible = usePageVisibility();
  const location = useLocation();
  const navigate = useNavigate();
  const authTimeoutRef = useRef(null);
  const [authModalOpen, setAuthModalOpen] = useState(false);

  // Track Location
  useEffect(() => {
    console.log('Location:', location.pathname);
    if (
      location.pathname !== '/' &&
      !location.pathname.includes('password-reset')
    ) {
      localStorage.setItem('last-path', location.pathname);
    }
  }, [location]);

  // Handle Authentication and User Fetching
  useEffect(() => {
    // Fetch User function
    const fetchUser = async () => {
      try {
        const user = await getUser();
        console.log({ user });
        setUser(user);
      } catch (error) {
        console.error(error);
      }
    };

    const jwt = getJwt();
    const isExpired = isJwtExpired();
    if (location.pathname.includes('password-reset')) {
      return;
    }
    if (!jwt || isExpired) {
      navigate('/');
    } else {
      fetchUser();
      startAuthTimeout();
      navigate(localStorage.getItem('last-path') || '/dashboard');
    }

    return () => {
      if (authTimeoutRef.current) {
        clearTimeout(authTimeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, []);

  // Handle Page Visibility API Events
  useEffect(() => {
    console.log(`Page is active: ${isPageVisible}`);
    if (!isPageVisible) {
      if (authTimeoutRef.current) {
        clearTimeout(authTimeoutRef.current);
        authTimeoutRef.current = null;
      }
    } else {
      if (!authTimeoutRef.current) {
        const isExpired = isJwtExpired();
        if (!isExpired && user) {
          startAuthTimeout();
        } else {
          if (user) {
            handleLogout();
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [isPageVisible]);

  const startAuthTimeout = () => {
    const jwtExpiration = getJwtExpiration();
    const now = Date.now();
    const time = jwtExpiration - now;
    const cushion = 300000;
    const minutes = (time - cushion) / 60000;
    if (minutes > 5) {
      console.log(`Minutes Before Auth Expiration: ${minutes}`);
      authTimeoutRef.current = setTimeout(handleAuthExpiration, time - cushion);
    } else {
      handleLogout();
    }
  };

  const handleAuthExpiration = () => {
    if (authTimeoutRef.current) {
      clearTimeout(authTimeoutRef.current);
    }
    setAuthModalOpen(true);
  };

  const handleLogout = () => {
    console.log('Logging out');
    navigate('/');
    setUser(null);
    logout();
  };

  const refreshToken = async () => {
    const success = await refreshJwt();
    console.log(`Token refreshed: ${success}`);
    if (success) {
      startAuthTimeout();
    }
  };

  return (
    <>
      <Routes>
        <Route index element={<Login startAuthTimeout={startAuthTimeout} />} />
        <Route path="password-reset" element={<PasswordReset />} />
        <Route
          path="dashboard"
          element={<DashboardLayout handleLogout={handleLogout} />}
        >
          <Route index element={<DashBoard />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:id" element={<User />} />
          <Route path="user-groups" element={<UserGroups />} />
          <Route path="user-groups/:id" element={<UserGroup />} />
          <Route path="roles" element={<Roles />} />
          <Route path="roles/:id" element={<Role />} />
          <Route path="template-files" element={<TemplateFiles />} />
          <Route path="template-files/:id" element={<TemplateFile />} />
          <Route path="input-fields" element={<InputFields />} />
          <Route path="input-fields/:id" element={<InputField />} />
          <Route path="bsft-sms-configs" element={<BsftSmsConfigs />} />
          <Route path="bsft-sms-configs/:id" element={<BsftSmsConfig />} />
          <Route path="bsft-email-configs" element={<BsftEmailConfigs />} />
          <Route path="bsft-email-configs/:id" element={<BsftEmailConfig />} />
          <Route path="wp-configs" element={<WpConfigs />} />
          <Route path="wp-configs/:id" element={<WpConfig />} />
          <Route path="broadcasts" element={<Broadcasts />} />
          <Route path="broadcasts/:id" element={<Broadcast />} />
          <Route
            path="placeholder-mappings"
            element={<PlaceholderMappings />}
          />
          <Route
            path="placeholder-mappings/:id"
            element={<PlaceholderMapping />}
          />
          <Route path="broadcast-templates" element={<BroadcastTemplates />} />
          <Route
            path="broadcast-templates/:id"
            element={<BroadcastTemplate />}
          />
          <Route path="hero-crop" element={<HeroCrop />} />
          <Route path="totd-marketing-push" element={<TotdMarketingPush />} />
          <Route path="pasta-docs" element={<PastaDocs />} />
          <Route path="table-builder" element={<TableBuilder />} />
          <Route path="rebrandly-links" element={<Rebrandly />} />
        </Route>
      </Routes>
      <AuthExpirationModal
        milliseconds={180000}
        open={authModalOpen}
        onClose={(reason) => {
          switch (reason) {
            case 'escapeKeyDown':
            case 'logout':
              setAuthModalOpen(false);
              handleLogout();
              break;
            case 'refresh':
              console.log('Refreshing token');
              setAuthModalOpen(false);
              refreshToken();
              break;
            default:
              break;
          }
        }}
      />
    </>
  );
}

export default App;
