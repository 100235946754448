import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupText,
  Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiUrl, baseUrl } from '../utils/url';
import useBreakpoint from '../utils/hooks/useBreakpoint';
import axios from 'axios';
import { toast } from 'react-toastify';

function PasswordReset() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const token = searchParams.get('token');
  const [newPassword, setNewPassword] = useState({
    password: '',
    password2: '',
  });
  const breakpoint = useBreakpoint();
  const [alertMessage, setAlertMessage] = useState({
    message: 'Reset your password.',
    color: 'cpblue-400',
  });

  const navigate = useNavigate();

  const resetPassword = async (e) => {
    e.preventDefault();

    if (newPassword.password !== newPassword.password2) {
      setAlertMessage({
        message: 'Passwords do not match...',
        color: 'cpred-400',
      });
      return;
    }
    const toastId = toast.loading('Resetting password...');

    try {
      const response = await axios.post(
        `${apiUrl}auth/forgot-password-update`,
        {
          newPassword: newPassword.password,
          id,
          token,
        },
      );
      console.log(response);
      if (response.data.success) {
        toast.update(toastId, {
          render: 'Password reset successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        });
        navigate('/');
      } else {
        toast.update(toastId, {
          render: 'Error resetting password...',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
        console.error(response);
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: 'Error resetting password...',
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="5" className="d-flex justify-content-center">
          <img
            src={`${baseUrl}cp-logo-400-light.png`}
            width="200px"
            alt="Copy Pasta"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="12" md="5">
          <Alert
            className="shadow rounded"
            color={alertMessage.color}
            isOpen={true}
          >
            <FontAwesomeIcon icon={faInfoCircle} />{' '}
            <span className="ms-2">{alertMessage.message}</span>
          </Alert>
          <Card
            className={`${
              breakpoint !== 'xs' && breakpoint !== 'sm' && 'shadow'
            } rounded mt-3 border-0`}
          >
            <CardBody>
              <Form onSubmit={resetPassword}>
                <FormGroup>
                  <Label for="new-password">New Password</Label>
                  <InputGroup>
                    <InputGroupText>
                      <FontAwesomeIcon icon={faLock} />
                    </InputGroupText>
                    <Input
                      type="password"
                      name="new-password"
                      id="new-password"
                      onChange={(e) =>
                        setNewPassword({
                          ...newPassword,
                          password: e.target.value,
                        })
                      }
                      value={newPassword.password}
                      placeholder="New Password"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="new-password-2">Confirm New Password</Label>
                  <InputGroup>
                    <InputGroupText>
                      <FontAwesomeIcon icon={faLock} />
                    </InputGroupText>
                    <Input
                      type="password"
                      name="new-password-2"
                      id="new-password-2"
                      onChange={(e) =>
                        setNewPassword({
                          ...newPassword,
                          password2: e.target.value,
                        })
                      }
                      value={newPassword.password2}
                      placeholder="New Password"
                    />
                  </InputGroup>
                </FormGroup>
                <Button block type="submit" color="cpblue-500">
                  Reset Password
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default PasswordReset;
