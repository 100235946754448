import React, { useEffect } from 'react';
import { Col, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

function BootstrapTextInput({
  label,
  value = null,
  fullWidth = false,
  isPassword = false,
  isTextArea = false,
  rows = 3,
  maxLength = undefined,
  countSubtract = 0,
  countAdd = 0,
  hide = false,
  required = false,
  ...props
}) {
  const [field, meta, { setValue }] = useField(props);

  useEffect(() => {
    if (value) {
      setValue(value, true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col md={fullWidth ? '12' : '6'} className={`${hide ? 'd-none' : ''}`}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>
          {required && (
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
          )}
          {label}
        </Label>
        <Input
          type={isPassword ? 'password' : isTextArea ? 'textarea' : 'text'}
          invalid={meta.touched && meta.error ? true : false}
          rows={rows}
          {...field}
          {...props}
        />
        {maxLength && (
          <small
            className={
              field.value?.length + countAdd - countSubtract <= maxLength
                ? 'text-cpblue-500'
                : 'text-danger'
            }
          >
            {field.value?.length + countAdd - countSubtract}/{maxLength}
          </small>
        )}
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default BootstrapTextInput;
