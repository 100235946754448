import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { apiUrl } from '../../../url';
import { getJwt } from '../../../jwt';

const useItemMutation = (key, shouldNavigate = true) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // UPDATE FUNCTION
  const update = (item) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: 'PUT',
        url: `${apiUrl}${key}s/${id}`,
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      };
      try {
        const { data } = await axios.request({ ...options, data: item });
        if (key === 'user' && item.password) {
          try {
            await updatePassword(item.password);
            resolve(data);
          } catch (error) {
            reject(error);
          }
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // UPDATE PASSWORD FUNCTION
  const updatePassword = (newPassword) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: 'POST',
        url: `${apiUrl}auth/password-update`,
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      };
      try {
        const { data } = await axios.request({
          ...options,
          data: { newPassword },
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // ADD FUNCTION
  const add = (item) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: 'POST',
        url: `${apiUrl}${key}s`,
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      };
      try {
        const { data } = await axios.request({ ...options, data: item });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // DELETE FUNCTION
  const _delete = (id) => {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: 'DELETE',
        url: `${apiUrl}${key}s/${id}`,
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      };
      try {
        const { data } = await axios.request(options);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // UPDATE MUTATION
  const updateMutation = useMutation(update, {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async () => {
      // ✅ invalidate query
      await queryClient.invalidateQueries([key, id]);
    },
  });

  // ADD MUTATION
  const addMutation = useMutation(add, {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async (newItem) => {
      // ✅ add item to existing items
      const prevItems = queryClient.getQueryData(`${key}s`);
      if (prevItems) {
        queryClient.setQueryData(`${key}s`, [...prevItems, newItem]);
      }
      if (shouldNavigate) {
        navigate(`/dashboard/${key}s/${newItem._id}`);
      }
    },
  });

  // DELETE MUTATION
  const deleteMutation = useMutation(_delete, {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async () => {
      // ✅ invalidate query
      await queryClient.invalidateQueries(`${key}s`);
    },
  });

  return {
    add: addMutation,
    _delete: deleteMutation,
    update: updateMutation,
  };
};

export default useItemMutation;
