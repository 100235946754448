import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, FormFeedback, FormGroup, Label } from 'reactstrap';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

function TypeaheadSingleInput({
  label,
  fullWidth = false,
  customOnChange = null,
  objects = false,
  valueKey = null,
  options = [],
  required = false,
  ...props
}) {
  const [{ onChange, value, ...field }, meta, { setValue }] = useField(props);

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>
          {required && (
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
          )}
          {label}
        </Label>
        <Typeahead
          filterBy={(option, props) => {
            if (props.selected && props.selected.length) {
              return true;
            } else {
              if (objects) {
                return (
                  option[props.labelKey]
                    .toLowerCase()
                    .indexOf(props.text.toLowerCase()) !== -1
                );
              } else {
                return (
                  option.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                );
              }
            }
          }}
          inputProps={{ id: props.id, name: props.name }}
          isInvalid={meta.touched && meta.error ? true : false}
          className={meta.touched && meta.error ? 'is-invalid' : ''}
          options={options}
          onChange={(selected) => {
            if (objects && valueKey) {
              if (customOnChange) {
                customOnChange(selected[0] || '');
              } else {
                setValue(
                  selected.map((option) => option[valueKey])[0] || '',
                  true,
                );
              }
            } else {
              if (customOnChange) {
                customOnChange(selected[0] || '');
              } else {
                setValue(selected[0] || '', true);
              }
            }
          }}
          selected={
            objects && valueKey
              ? options.filter((option) => value === option[valueKey])
              : value?.length > 0
              ? [value]
              : []
          }
          {...field}
          {...props}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default TypeaheadSingleInput;
