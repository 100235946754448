import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import { UserProvider } from './utils/contexts/userContext';
import * as serviceWorker from './serviceWorker';
import './index.css';
// import './App.css';
import 'draft-js/dist/Draft.css';
import 'draftail/dist/draftail.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import 'croppie/croppie.css';
import { ConfirmActionModalProvider } from './utils/contexts/confimActionModalContext';
import { ToastContainer, toast } from 'react-toastify';

function CustomQueryClientProvider({ children }) {
  const queryErrorHandler = (error) => {
    const message =
      error instanceof Error ? error.message : 'error connecting to server';
    console.error(message);
    toast.error(message);
  };

  const queryClientRef = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          onError: queryErrorHandler,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={queryClientRef.current}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <CustomQueryClientProvider>
      <ConfirmActionModalProvider>
        <UserProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UserProvider>
      </ConfirmActionModalProvider>
    </CustomQueryClientProvider>
    <ToastContainer
      hideProgressBar
      theme="colored"
      pauseOnFocusLoss={false}
      pauseOnHover={false}
    />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
