import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  SaveButton,
  TypeaheadMultiInput,
  TypeaheadSingleInput,
  TypeaheadTagsInput,
  FormCard,
} from '../../components/forms';
import { _WP_CONFIG } from '../../utils/empties';
import {
  useAllQuery,
  useSingleQuery,
} from '../../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';

const WpConfigSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tags: Yup.array().of(Yup.string()),
  site: Yup.string().required('Site is required'),
  authorId: Yup.string().required('Author is required'),
  categoryIds: Yup.array()
    .of(Yup.string())
    .min(1, 'Must provide at least one category')
    .required('Categories is required'),
  pubCodeIds: Yup.array().of(Yup.string()),
  portfolio: Yup.string(),
});

function WpConfig() {
  const { id } = useParams();
  const {
    data = _WP_CONFIG,
    isLoading,
    isError,
    error,
  } = useSingleQuery('wp-config', id);
  const { add, update } = useItemMutation('wp-config');
  const { data: authors = {} } = useAllQuery('wp-authors');
  const { data: categories = {} } = useAllQuery('wp-categories');
  const { data: pubcodes = {} } = useAllQuery('wp-pubcodes');

  const renderSiteSpecifics = (values) => {
    if (values.site.length > 0) {
      return (
        <>
          <Row>
            <TypeaheadSingleInput
              label="Author"
              id="authorId"
              name="authorId"
              placeholder="Author"
              options={
                authors.hasOwnProperty(values.site) ? authors[values.site] : []
              }
              objects
              labelKey="display_name"
              valueKey="id"
            />
            <TypeaheadMultiInput
              label="Categories"
              id="categoryIds"
              name="categoryIds"
              placeholder="Categories"
              options={
                categories.hasOwnProperty(values.site)
                  ? categories[values.site]
                  : []
              }
              objects
              labelKey="name"
              valueKey="term_id"
              renderMenuItemChildren={(option) => (
                <div>
                  {option.name}
                  <div>
                    <small>Slug: {option.slug}</small>
                  </div>
                </div>
              )}
            />
            <TypeaheadMultiInput
              fullWidth
              label="Pub Codes"
              id="pubCodeIds"
              name="pubCodeIds"
              placeholder="Pub Codes"
              options={
                pubcodes.hasOwnProperty(values.site)
                  ? pubcodes[values.site]
                  : []
              }
              objects
              labelKey="name"
              valueKey="term_id"
            />
          </Row>
        </>
      );
    } else {
      return <div />;
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving web config...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved web config!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save web config...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved web config!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save web config...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/wp-configs"
          breadcrumbLabel="WordPress Configs"
          breadcrumbItem={
            data.name.length > 0 ? data.name : 'New WordPress Config'
          }
        >
          <Formik
            initialValues={data}
            validationSchema={WpConfigSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isValid, isSubmitting, values }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                  <TypeaheadSingleInput
                    fullWidth
                    label="Site"
                    id="site"
                    name="site"
                    placeholder="Site"
                    options={['manwardpress', 'mtatradeoftheday', 'oxfordclub']}
                  />
                </Row>
                {renderSiteSpecifics(values)}
                <Row>
                  <BootstrapTextInput
                    label="Portfolio"
                    id="portfolio"
                    name="portfolio"
                    placeholder="Portfolio"
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default WpConfig;
