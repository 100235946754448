import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data, labels, tooltipLabel, backgroundColors }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: tooltipLabel,
        data: data,
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={chartData} />;
};

export default DoughnutChart;
