import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  FormCard,
  SaveButton,
  TypeaheadMultiInput,
} from '../../components/forms';
import { _USER_GROUP } from '../../utils/empties';
import {
  useAllQuery,
  useSingleQuery,
} from '../../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';

const UserGroupSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  users: Yup.array().of(Yup.string()),
});

function UserGroup() {
  const { id } = useParams();
  const {
    data = _USER_GROUP,
    isLoading,
    isError,
    error,
  } = useSingleQuery('user-group', id);
  const { update, add } = useItemMutation('user-group');
  const { data: users } = useAllQuery('users');

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving user group...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved user group!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save user group...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved user group!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save user group...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/user-groups"
          breadcrumbLabel="User Groups"
          breadcrumbItem={data.name.length > 0 ? data.name : 'New User Group'}
        >
          <Formik
            initialValues={data}
            validationSchema={UserGroupSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadMultiInput
                    label="Users"
                    id="users"
                    name="users"
                    placeholder="Users"
                    objects
                    labelKey="username"
                    valueKey="_id"
                    options={users}
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default UserGroup;
