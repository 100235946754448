import React from 'react';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import CountsByDateBarChart from '../components/dashboardWidgets/CountsByDateBarChart';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import DoughnutChart from '../components/dashboardWidgets/DoughnutChart';

function averagePerDay(dateStrings) {
  // Convert date strings to Date objects
  const dates = dateStrings.map((dateString) => new Date(dateString));

  // Sort Date objects in ascending order
  dates.sort((a, b) => a - b);

  // Calculate total number of days covered
  const totalDays =
    Math.round((dates[dates.length - 1] - dates[0]) / (1000 * 60 * 60 * 24)) +
    1;

  // Count number each day
  const perDay = new Array(totalDays).fill(0);
  dates.forEach((date) => {
    const dayIndex = Math.round((date - dates[0]) / (1000 * 60 * 60 * 24));
    perDay[dayIndex]++;
  });

  // Calculate average number per day
  const total = perDay.reduce((acc, val) => acc + val, 0);
  const average = total / totalDays;

  return average;
}

function DashBoard() {
  const { data: launchedDates = [] } = useAllQuery('broadcast-launched-dates');
  const { data: createdDates = [] } = useAllQuery('broadcast-created-dates');
  const {
    data: launchedCampaignCounts = {
      bsftEmailCampaigns: 0,
      bsftSmsCampaigns: 0,
      webPosts: 0,
    },
  } = useAllQuery('broadcast-launched-campaign-counts');

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Dashboard</h1>
        </Col>
      </Row>
      <Row className="mt-3">
        {createdDates.length > 0 && (
          <Col md={6} className="h-200 mb-4">
            <Card color="cpblue-500" outline className="shadow">
              <CardBody>
                <CardTitle tag="h5">Broadcasts Created</CardTitle>
                <Row>
                  <Col>
                    <span className="fw-light">
                      Total: {createdDates.length} | Average per day:{' '}
                      {averagePerDay(createdDates).toFixed(2)}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <CountsByDateBarChart
                  dates={createdDates}
                  label="Broadcasts Created"
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {launchedDates.length > 0 && (
          <Col md={6} className="h-200 mb-4">
            <Card color="cpblue-500" outline className="shadow">
              <CardBody>
                <CardTitle tag="h5">Broadcasts Launched</CardTitle>
                <Row>
                  <Col>
                    <span className="fw-light">
                      Total: {launchedDates.length} | Average per day:{' '}
                      {averagePerDay(launchedDates).toFixed(2)}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <CountsByDateBarChart
                  dates={launchedDates}
                  label="Broadcasts Launched"
                />
              </CardBody>
            </Card>
          </Col>
        )}
        {launchedCampaignCounts && (
          <Col md={6} className="mb-4">
            <Card color="cpblue-500" outline className="shadow">
              <CardBody>
                <CardTitle tag="h5">Launched Campaign Counts by Type</CardTitle>
                <Row>
                  <Col>
                    <span className="fw-light">
                      Total:{' '}
                      {launchedCampaignCounts.bsftEmailCampaigns +
                        launchedCampaignCounts.bsftSmsCampaigns +
                        launchedCampaignCounts.webPosts}
                    </span>
                    <hr />
                  </Col>
                </Row>
                <DoughnutChart
                  data={[
                    launchedCampaignCounts.bsftEmailCampaigns,
                    launchedCampaignCounts.bsftSmsCampaigns,
                    launchedCampaignCounts.webPosts,
                  ]}
                  labels={['Email', 'SMS', 'Web']}
                  tooltipLabel="Campaigns"
                  backgroundColors={['#07a6f5', '#f52035', '#f5ee20']}
                />
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default DashBoard;
