import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, FormFeedback, FormGroup, Label } from 'reactstrap';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import { useField } from 'formik';
import { useAllQuery } from '../../utils/hooks/reactQuery/queries';

function TypeaheadTagsInput({ fullWidth = false }) {
  const { data: tags = [] } = useAllQuery('tags');
  const { add: addTag } = useItemMutation('tag', false);
  const [{ onChange, value, ...field }, meta, { setValue }] = useField('tags');

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor="tags">Tags</Label>
        <Typeahead
          id="tags"
          name="tags"
          inputProps={{ id: 'tags', name: 'tags' }}
          multiple
          isInvalid={meta.touched && meta.error ? true : false}
          className={meta.touched && meta.error ? 'is-invalid' : ''}
          options={tags}
          placeholder="Tags"
          labelKey="name"
          onChange={(selected) => {
            setValue(
              selected.map((tag) => {
                if (tag.hasOwnProperty('customOption')) {
                  // This is a new tag
                  addTag.mutate(
                    { name: tag.name },
                    {
                      onSuccess: () => {
                        console.log('Added New Tag!');
                      },
                      onError: (error) => {
                        console.error(error);
                      },
                    },
                  );
                }
                return tag.name;
              }),
              true,
            );
          }}
          selected={tags.filter((tag) => value.includes(tag.name))}
          allowNew={true}
          {...field}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default TypeaheadTagsInput;
