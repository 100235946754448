import { useEffect, useRef, useState } from 'react';

const usePageVisibility = () => {
  const visibleRef = useRef(true);
  const [visible, setVisible] = useState(visibleRef.current);
  const documentRef = useRef(document);
  const configRef = useRef(null);

  // Set the name of the hidden property and the change event for visibility
  if (typeof documentRef.current.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    configRef.current = {
      hiddenKey: 'hidden',
      visibilityChangeEvent: 'visibilitychange',
    };
  } else if (typeof documentRef.current.msHidden !== 'undefined') {
    configRef.current = {
      hiddenKey: 'msHidden',
      visibilityChangeEvent: 'msvisibilitychange',
    };
  } else if (typeof documentRef.current.webkitHidden !== 'undefined') {
    configRef.current = {
      hiddenKey: 'webkitHidden',
      visibilityChangeEvent: 'webkitvisibilitychange',
    };
  }

  useEffect(() => {
    if (
      typeof documentRef.current.addEventListener === 'undefined' ||
      configRef.current?.hiddenKey === undefined
    ) {
      console.log(
        'This feature requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.',
      );
    } else {
      // Handle page visibility change
      documentRef.current.addEventListener(
        configRef.current.visibilityChangeEvent,
        () => {
          if (documentRef.current[configRef.current?.hiddenKey]) {
            visibleRef.current = false;
            setVisible(false);
          } else {
            visibleRef.current = true;
            setVisible(true);
          }
        },
        false,
      );
    }
  }, []);

  return visible;
};

export default usePageVisibility;
