export function htmlToEmail(html, { LinkColor }) {
  // we want to add newlines where any p tags meet up with lists
  html = html.replace(/<\/p>\n<ul>/g, '\n<ul>');
  html = html.replace(/<\/p>\n<ol>/g, '\n<ol>');
  // let's style our lists
  html = styleLists(html);
  // swap entities with the actual characters
  html = entitiesToCharacters(html);
  // wrap any images in the proper tables
  html = wrapImgTable(html);
  // lastly we want to add the basic styles to the anchor tags. mostly the link color for any pubs with special colors
  html = html.replace(/<a/g, `<a style="color: #${LinkColor};"`);

  return html;
}

function styleLists(html) {
  // we want to add our styles to our lists
  var first; // this will let us keep track of if we are on the first li element
  var numOccurLists = (html.match(/<ul>/g) || []).length;
  var start = 0;
  var end;
  var liStart;
  var liEnd;

  // let's loop through the unordered lists first
  for (var i = 0; i < numOccurLists; i++) {
    start = html.indexOf('<ul>');
    end = start + 4;

    // this replaces our empty ul with one that includes our styles
    html = html.replaceBetween(
      start,
      end,
      '<ul style="padding: 0; margin: 0px 0px 0px 40px;">',
    );

    // while we still know where this ul is, let's style its li elements
    var endUL = html.indexOf('</ul>', start) + 5;
    var list = html.substring(start, endUL);
    var numOccurLi = (list.match(/<li>/g) || []).length;

    first = true;

    for (var j = 0; j < numOccurLi; j++) {
      liStart = list.indexOf('<li>');
      liEnd = liStart + 4;

      // is this the first li element
      if (first) {
        list = list.replaceBetween(
          liStart,
          liEnd,
          '<li style="margin: 21px 0px 21px 0px;">',
        );

        first = false;
      } else {
        list = list.replaceBetween(
          liStart,
          liEnd,
          '<li style="margin: 0px 0px 21px 0px;">',
        );
      }
    }

    // now our list substring should have all of its styles so we can insert it back into our string
    html = html.replaceBetween(start, endUL, list);

    start += list.length; // this will ensure that our next time through the loop we start our search after the list we already styled
  }

  // let's reset our values and set up for ordered lists

  first = true;
  numOccurLists = (html.match(/<ol type="1">/g) || []).length;
  start = 0;
  end = 0;
  liStart = 0;
  liEnd = 0;

  for (var k = 0; k < numOccurLists; k++) {
    start = html.indexOf('<ol type="1">');
    end = start + 13;

    // this replaces our empty ol with one that includes our styles
    html = html.replaceBetween(
      start,
      end,
      '<ol style="padding: 0; margin: 0px 0px 0px 40px;">',
    );

    // while we still know where this ul is, let's style its li elements
    endUL = html.indexOf('</ol>', start) + 5;
    list = html.substring(start, endUL);
    numOccurLi = (list.match(/<li>/g) || []).length;

    first = true;

    for (var l = 0; l < numOccurLi; l++) {
      liStart = list.indexOf('<li>');
      liEnd = liStart + 4;

      // is this the first li element
      if (first) {
        list = list.replaceBetween(
          liStart,
          liEnd,
          '<li style="margin: 21px 0px 21px 0px;">',
        );

        first = false;
      } else {
        list = list.replaceBetween(
          liStart,
          liEnd,
          '<li style="margin: 0px 0px 21px 0px;">',
        );
      }
    }

    // now our list substring should have all of its styles so we can insert it back into our string
    html = html.replaceBetween(start, endUL, list);

    start += list.length; // this will ensure that our next time through the loop we start our search after the list we already styled
  }

  return html;
}

function wrapImgTable(html) {
  let numOccurImageTag = (html.match(/<img/g) || []).length;
  let start;
  let end = 0;

  for (let i = 0; i < numOccurImageTag; i++) {
    start = html.indexOf('<img', end);
    end = html.indexOf('>', start) + 1;

    let imgTag = html.substring(start, end);

    if (imgTag.includes('inserted')) {
      let preIMG = html.substring(0, start);

      let endPre = preIMG.length;

      start = html.indexOf('<img', endPre);
      end = html.indexOf('>', start) + 1;
      imgTag = html.substring(start, end);

      let srcStart = imgTag.indexOf('src="') + 5;
      let srcEnd = imgTag.indexOf('"', srcStart);
      let imgSRC = imgTag.substring(srcStart, srcEnd);

      let altStart = imgTag.indexOf('alt="') + 5;
      let altEnd = imgTag.indexOf('"', altStart);
      let imgALT = imgTag.substring(altStart, altEnd);

      let linkStart;
      let linkEnd;
      let imgLink = null;

      if (imgTag.includes('data-link')) {
        linkStart = imgTag.indexOf('data-link="') + 11;
        linkEnd = imgTag.indexOf('"', linkStart);
        imgLink = imgTag.substring(linkStart, linkEnd);
      }

      let imageAttributes = [imgSRC, imgALT, imgLink];

      let table =
        imgLink === null
          ? `<table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; border-collapse: collapse; Margin: 0; padding: 0;">
                        <tr><td align="center" style="text-align: center; Margin: 0; padding: 0;">
                        <img width="550" alt="${imageAttributes[1]}" src="${imageAttributes[0]}" style="width: 550px; max-width: 100%; display: block; Margin: 0 auto;" />
                        </td></tr>
                        <tr><td style="font-size: 16px; line-height: 21px;">&nbsp;</td></tr>
                        </table>\n`
          : `<table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; border-collapse: collapse; Margin: 0; padding: 0;">
                        <tr><td style="font-size: 16px; line-height: 21px;">&nbsp;</td></tr>
                        <tr><td align="center" style="text-align: center; Margin: 0; padding: 0;">
                        <a href="${imageAttributes[2]}" target="_blank">
                        <img width="550" alt="${imageAttributes[1]}" src="${imageAttributes[0]}" style="width: 550px; max-width: 100%; display: block; Margin: 0 auto;" />
                        </a>
                        </td></tr>
                        <tr><td style="font-size: 16px; line-height: 21px;">&nbsp;</td></tr>
                        </table>\n`;

      html = html.replaceBetween(start, end, table);
      end = html.indexOf('</table>', end);
    } else {
      html = html.replaceBetween(start, end, '');
    }
  }

  return html;
}

export function entitiesToCharacters(html) {
  html = html.replace(/&nbsp;/g, ' '); // remove any nbsp that got added
  html = html.replace(/&rsquo;/g, "'"); // replace rsquo with '
  html = html.replace(/&lsquo;/g, "'"); // replace lsquo with '
  html = html.replace(/&ldquo;/g, '"'); // replace ldquo with "
  html = html.replace(/&rdquo;/g, '"'); // replace rdquo with "
  html = html.replace(/&quot;/g, '"'); // replace quot with "
  html = html.replace(/&#39;/g, "'"); // replace #39 with '
  html = html.replace(/&lt;/g, '<'); // replace lt with <
  html = html.replace(/&gt;/g, '>'); // replace gt with >
  html = html.replace(/&amp;/g, '&'); // replace amp with &

  return html;
}

// eslint-disable-next-line
String.prototype.replaceBetween = function (start, end, what) {
  return this.substring(0, start) + what + this.substring(end);
};
