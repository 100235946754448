import React, { useState } from 'react';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormText,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
} from 'reactstrap';
import { EditorState, Modifier } from 'draft-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';

function LinkSource(props) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [url, setUrl] = useState('');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    const { editorState, onComplete } = props;
    onComplete(editorState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { editorState, entityType, onComplete } = props;

    if (url !== '') {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        entityType.type,
        'MUTABLE',
        { url },
      );
      const selectionState = editorState.getSelection();
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const contentStateWithLink = Modifier.applyEntity(
        contentStateWithEntity,
        selectionState,
        entityKey,
      );
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithLink,
      });

      onComplete(newEditorState);
    } else {
      onComplete(editorState);
    }
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalHeader className="text-bg-cpblue-500">
        <FontAwesomeIcon icon={faLink} />{' '}
        <span className="ms-2">Insert Link</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Form onSubmit={(values) => handleSubmit(values)}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="url">Url</Label>
                    <Input
                      type="text"
                      id="url"
                      name="url"
                      placeholder="Url"
                      className="form-control form-control-sm"
                      required
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                    <FormText color="muted">
                      Please provide a valid url.
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button block color="cpblue-500" type="submit">
                    Insert
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default LinkSource;
