import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  Row,
  Col,
  Button,
  ModalBody,
  Input,
  FormText,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useUser } from '../utils/contexts/userContext';

function TestEmailModal({ open, onClose, defaultEmail }) {
  const {
    data: users,
    isLoading,
    isError,
    error,
    status,
    isFetching,
  } = useAllQuery('users');
  const { user } = useUser();
  const [emailList, setEmailList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [personalizeFor, setPersonalizeFor] = useState('');

  useEffect(() => {
    if (defaultEmail !== '') {
      if (!emailList.includes(defaultEmail)) {
        setEmailList([defaultEmail, ...emailList]);
      }
      if (!selectedEmails.includes(defaultEmail)) {
        setSelectedEmails([defaultEmail, ...selectedEmails]);
      }
    }
    // eslint-disable-next-line
  }, [defaultEmail]);

  useEffect(() => {
    if (status === 'success') {
      if (users) {
        const usersEmails = users.map((user) => user.username);
        if (defaultEmail !== '') {
          setEmailList([defaultEmail, ...usersEmails]);
        } else {
          setEmailList(usersEmails);
        }
      }
    }
    // eslint-disable-next-line
  }, [status, users, isFetching]);

  const setMyself = () => {
    setSelectedEmails([user.username]);
  };

  const setDefault = () => {
    setSelectedEmails([defaultEmail]);
  };

  return (
    <Modal isOpen={open} toggle={() => onClose([], personalizeFor, 'cancel')}>
      <ModalHeader className="text-bg-cpblue-500">
        <FontAwesomeIcon icon={faEnvelopeCircleCheck} />{' '}
        <span className="ms-2">Email Test</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>Who should we send the test to?</p>
          </Col>
        </Row>
        {isLoading ? (
          <h5>Loading...</h5>
        ) : isError ? (
          <h5>Error: {error.message}</h5>
        ) : (
          <>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="mb-2">
                      <Label for="recipients">
                        Recipients <span className="text-danger">*</span>
                      </Label>
                      <Typeahead
                        id="recipients"
                        name="recipients"
                        multiple
                        options={emailList}
                        selected={selectedEmails}
                        onChange={(selected) => {
                          setSelectedEmails(
                            selected.map((value) =>
                              typeof value === 'string' ? value : value.label,
                            ),
                          );
                        }}
                        placeholder="Emails"
                        allowNew={true}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-row justify-content-center align-items-center">
                    <Button
                      color="cpblue-300"
                      block
                      onClick={() => {
                        setMyself();
                      }}
                    >
                      Myself
                    </Button>
                    <Button
                      className="ms-2"
                      color="cpblue-300"
                      block
                      onClick={() => {
                        setDefault();
                      }}
                    >
                      Default
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="pt-3">
              <hr />
              <Col>
                <FormGroup>
                  <Label for="personalizeFor">Personalize For</Label>
                  <Input
                    id="personalizeFor"
                    name="personalizeFor"
                    onChange={(e) => setPersonalizeFor(e.target.value)}
                    value={personalizeFor}
                    placeholder="Email for personalization"
                  />
                  <FormText>
                    OPTIONAL: Email of the user to personalize the test send
                    for.
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <Button
                  color="cpblue-500"
                  block
                  onClick={() => {
                    onClose(selectedEmails, personalizeFor, 'send');
                  }}
                >
                  Send
                </Button>
                <Button
                  className="mt-2"
                  color="cpblue-100"
                  block
                  onClick={() => {
                    onClose([], personalizeFor, 'cancel');
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default TestEmailModal;
