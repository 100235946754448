import { replaceBetween } from './strings';

// Function to format the final html to be prettier
export const formatHtml = (html) => {
  html = html.replace(/&#x27;/g, "'");
  html = html.replace(/&#x60;/g, '`');
  html = html.replace(/&lt;/g, '<');
  html = html.replace(/&gt;/g, '>');
  html = html.replace(/&amp;/g, '&');
  html = html.replace(/&nbsp;/g, ' ');
  html = html.replace(/>\s+</g, '><');
  html = html.replace(/>\n</g, '><');
  // i want to remove any whitespace before a closing p tag
  // also remove any whitespace after an opening p tag
  html = html.replace(/\s+<\/p>/g, '</p>');
  html = html.replace(/<p>\s+/g, '<p>');
  // let's also remove any unnecessary line breaks
  html = html.replace(/\n/g, ' ');

  // now let's format the html code to make it more readable
  html = html.replace(/\/p><p/g, '/p>\n<p'); // opening and closing p tags
  html = html.replace(/\/p><img/g, '/p>\n<img'); // closing p and image tags
  html = html.replace(/><p/g, '>\n<p'); // closing image and opening p tags
  html = html.replace(/<\/blockquote><blockquote>/g, ''); // closing blockquote and opening blockquote tags
  html = html.replace(/><blockquote/g, '>\n<blockquote'); // closing tags and opening blockquote tags
  html = html.replace(/\/p><\/blockquote/g, '/p>\n</blockquote'); // closing p and closing blockquote tags
  html = html.replace(/\/blockquote></g, '/blockquote>\n<'); // closing blockquote and opening tags
  html = html.replace(/\/ul><p/g, '/ul>\n<p'); // closing ul and opening p tags
  html = html.replace(/\/ol><p/g, '/ol>\n<p'); // closing ol and opening p tags
  html = html.replace(/\/p><ul/g, '/p>\n<ul'); // closing p and opening ul tags
  html = html.replace(/\/p><ol/g, '/p>\n<ol'); // closing p and opening ol tags
  html = html.replace(/><li/g, '>\n<li'); // closing tags and li tags
  html = html.replace(/\/li></g, '/li>\n<'); // closing li and opening tags

  // any closing and opening inline tags should just be spaces
  html = html.replace(/<\/em><em>/g, ' ');
  html = html.replace(/<\/strong><strong>/g, ' ');
  html = html.replace(/<\/u><u>/g, ' ');

  // lastly I just want to remove any double spaces
  html = html.replace(/\s{2,}/g, ' '); // make sure we didn't add any double spaces anywhere

  return html;
};

// Function to parse plain html to email friendly html
export const htmlToEmail = (html) => {
  // we want to add newlines where any p tags meet up with lists
  html = html.replace(/<\/p>\n<ul>/g, '\n<ul>');
  html = html.replace(/<\/p>\n<ol>/g, '\n<ol>');
  // let's style our lists
  html = styleLists(html, 'ol');
  html = styleLists(html, 'ul');
  // swap entities with the actual characters
  html = entitiesToCharacters(html);
  // wrap any images in the proper tables
  html = wrapImgTable(html);

  return html;
};

// HELPER FUNCTIONS
//---------------------------------------

// Style any <ul> or <ol>
const styleLists = (html, type) => {
  // we want to add our styles to our lists
  let regex = new RegExp(String.raw`<${type}>`, 'g');
  let first; // this will let us keep track of if we are on the first li element
  let numOccurLists = (html.match(regex) || []).length;
  let start = 0;
  let end;
  let liStart;
  let liEnd;

  // let's loop through the unordered lists first
  for (let i = 0; i < numOccurLists; i++) {
    start = html.indexOf(`<${type}>`);
    end = start + 4;
    // this replaces our empty tag with one that includes our styles
    html = replaceBetween(
      html,
      start,
      end,
      `<${type} style="padding: 0; margin: 0px 0px 0px 40px;">`,
    );
    // while we still know where this tag is, let's style its li elements
    let endTag = html.indexOf(`</${type}>`, start) + 5;
    let list = html.substring(start, endTag);
    let numOccurLi = (list.match(/<li>/g) || []).length;
    // Set first to true
    first = true;
    // Loop through <li>'s
    for (let j = 0; j < numOccurLi; j++) {
      liStart = list.indexOf('<li>');
      liEnd = liStart + 4;
      // is this the first li element
      if (first) {
        list = replaceBetween(
          list,
          liStart,
          liEnd,
          '<li style="margin: 21px 0px 21px 0px;">',
        );
        first = false;
      } else {
        list = replaceBetween(
          list,
          liStart,
          liEnd,
          '<li style="margin: 0px 0px 21px 0px;">',
        );
      }
    }
    // now our list substring should have all of its styles so we can insert it back into our string
    html = replaceBetween(html, start, endTag, list);
    // this will ensure that our next time through the loop we start our search after the list we already styled
    start += list.length;
  }
  return html;
};

const wrapImgTable = (html) => {
  // Inject our html into a div
  let container = document.createElement('div');
  container.innerHTML = html;
  // Find all <img> tags and loop through them
  let imageTags = container.getElementsByTagName('img');
  for (let image of imageTags) {
    // Is this an inserted image?
    if (image.dataset.inserted === 'true') {
      // First let's grab the attributes we need from the image
      let source = image.src;
      let altText = image.alt;
      let url = image.dataset.link || null;
      // Now that we have our attributes, let's create the required elements
      let tableElement = document.createElement('table');
      tableElement.setAttribute('width', '100%');
      tableElement.setAttribute('cellspacing', '0');
      tableElement.setAttribute('cellpadding', '0');
      tableElement.setAttribute(
        'style',
        'width: 100%; border-collapse: collapse; Margin: 0; padding: 0;',
      );
      let firstRow = document.createElement('tr');
      let secondRow = document.createElement('tr');
      secondRow.innerHTML =
        '<td style="font-size: 16px; line-height: 21px;">&nbsp;</td>';
      let imageCell = document.createElement('td');
      imageCell.setAttribute('align', 'center');
      imageCell.setAttribute(
        'style',
        'text-align: center; Margin: 0; padding: 0;',
      );
      let imageElement = document.createElement('img');
      imageElement.setAttribute('width', '550');
      imageElement.setAttribute('src', source);
      imageElement.setAttribute('alt', altText);
      imageElement.setAttribute(
        'style',
        'width: 550px; max-width: 100%; display: block; Margin: 0 auto;',
      );
      let anchorElement;
      if (url) {
        anchorElement = document.createElement('a');
        anchorElement.setAttribute('href', url);
        anchorElement.setAttribute('target', '_blank');
        anchorElement.appendChild(imageElement);
      }
      // Let's mash it all together
      imageCell.appendChild(anchorElement || imageElement);
      firstRow.appendChild(imageCell);
      tableElement.appendChild(firstRow);
      tableElement.appendChild(secondRow);
      // Now we just need to replace our image with our new table
      container.replaceChild(tableElement, image);
    }
  }

  return container.innerHTML;
};

export const entitiesToCharacters = (html) => {
  html = html.replace(/&nbsp;/g, ' '); // remove any nbsp that got added
  html = html.replace(/&rsquo;/g, "'"); // replace rsquo with '
  html = html.replace(/&lsquo;/g, "'"); // replace lsquo with '
  html = html.replace(/&ldquo;/g, '"'); // replace ldquo with "
  html = html.replace(/&rdquo;/g, '"'); // replace rdquo with "
  html = html.replace(/&quot;/g, '"'); // replace quot with "
  html = html.replace(/&#39;/g, "'"); // replace #39 with '
  html = html.replace(/&lt;/g, '<'); // replace lt with <
  html = html.replace(/&gt;/g, '>'); // replace gt with >
  html = html.replace(/&amp;/g, '&'); // replace amp with &

  return html;
};
