import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons';
import useBreakpoint from '../utils/hooks/useBreakpoint';

function NewItemButton({ text, customOnClick = null, extraClasses = '' }) {
  const breakpoint = useBreakpoint();

  if (customOnClick) {
    return (
      <Button
        onClick={() => customOnClick()}
        color="cpblue-500"
        className={`${
          breakpoint !== 'xs' && breakpoint !== 'sm' && 'shadow'
        } d-inline-flex align-items-center ${extraClasses}`}
      >
        <FontAwesomeIcon icon={faPlus} /> <span className="ms-2">{text}</span>
      </Button>
    );
  }
  return (
    <Link to={`new`} className="text-decoration-none">
      <Button
        color="cpblue-500"
        className={`${
          breakpoint !== 'xs' && breakpoint !== 'sm' && 'shadow'
        } d-inline-flex align-items-center ${extraClasses}`}
      >
        <FontAwesomeIcon icon={faPlus} /> <span className="ms-2">{text}</span>
      </Button>
    </Link>
  );
}

export default NewItemButton;
