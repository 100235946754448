import React, { useState } from 'react';
import { Draftail } from '../../../components/draftail';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-searchbox';
import axios from 'axios';
import { apiUrl } from '../../../utils/url';
import { getJwt } from '../../../utils/jwt';
import { buildHtmlEmail } from '../../../utils/email';
import { Button, Col, Row } from 'reactstrap';

function Email({
  initialHtml,
  itemCode,
  setContentHtml,
  setAssembledHtml,
  emailTemplate,
  options,
  keywordsEnabled,
  externalKey,
  sendEmailTest,
  emailTemplateStatus,
  launched,
}) {
  // State for our Assembled Email Template while in this component
  const [internalAssembledHtml, setInternalAssembledHtml] =
    useState(emailTemplate);
  // Modifier To Force Editor Render
  const [keyModifier, setKeyModifier] = useState(0);
  // Code or Rich Text Editor
  const [codeEditor, setCodeEditor] = useState(false);

  // Update HTML
  const updateContentHtml = async (html) => {
    // Set Content Html
    setContentHtml(html);

    let emailContentHtml = html;
    const axiosOptions = {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    };

    // Parse Placeholder Mappings
    try {
      const { data: contentWithMappings } = await axios.post(
        `${apiUrl}broadcasts/parse-placeholders`,
        {
          content: emailContentHtml,
          method: 'ADD',
        },
        axiosOptions,
      );

      emailContentHtml = contentWithMappings;
      console.log('Parsed content for placeholders!');

      if (keywordsEnabled) {
        console.log('Keywords are enabled!');
        try {
          const { data: contentWithKeywords } = await axios.post(
            `${apiUrl}decision-vision/get-keywords`,
            {
              list: itemCode,
              content: emailContentHtml,
            },
            axiosOptions,
          );

          emailContentHtml = contentWithKeywords.content;
          console.log('Parsed content for keywords!');
        } catch (error) {
          console.error(error);
        }
      }

      // Build Assembled HTML
      const assembledHtmlEmail = buildHtmlEmail(
        emailContentHtml,
        emailTemplate,
        options,
      );

      setInternalAssembledHtml(assembledHtmlEmail);
      setAssembledHtml(assembledHtmlEmail);
    } catch (error) {
      console.error(error);
    }
  };

  // Update Assembled HTML
  const updateAssembledHtml = (html) => {
    setAssembledHtml(html);
    setInternalAssembledHtml(html);
  };

  // Switch to Code Editor
  const toggleCodeEditor = async () => {
    if (codeEditor) {
      setKeyModifier((old) => old + 1);
      setCodeEditor(false);
    } else {
      setCodeEditor(true);
    }
  };

  return (
    <Col>
      <Row>
        <Col>
          <p>Changes made here will be reflected in the email only...</p>
        </Col>
      </Row>
      <Row className="mb-3 d-flex align-items-center">
        {emailTemplateStatus && (
          <Col>
            <p className="mb-0">
              To view the email template in Blueshift,{' '}
              <a
                href={`https://app.getblueshift.com/dashboard#/app/email_template_studio/${emailTemplateStatus.uuid}/edit/templates/html`}
                target="_blank"
                rel="noreferrer"
              >
                click here
              </a>
              .
            </p>
          </Col>
        )}
        <Col>
          <Button
            className="float-end ms-2"
            onClick={toggleCodeEditor}
            color="cpblue-300"
          >
            {codeEditor ? 'Edit Rich Text' : 'Edit HMTL'}
          </Button>
          <Button
            onClick={sendEmailTest}
            color="cpblue-500"
            className="float-end"
            disabled={launched}
          >
            Send Test Email
          </Button>
        </Col>
      </Row>
      {codeEditor ? (
        <AceEditor
          fontSize={16}
          showPrintMargin={false}
          wrapEnabled={true}
          width="100%"
          mode="html"
          theme={'tomorrow'}
          name="email-content-ace-editor"
          editorProps={{ $blockScrolling: true }}
          value={internalAssembledHtml}
          onChange={updateAssembledHtml}
          setOptions={{
            indentedSoftWrap: false,
            printMarginColumn: 90,
            useWorker: false,
          }}
        />
      ) : (
        <Draftail
          key={`rich-email-editor-${keyModifier}-${externalKey}`}
          initialHtml={initialHtml}
          setHtml={updateContentHtml}
          showTopToolbar={true}
        />
      )}
    </Col>
  );
}

export default Email;
