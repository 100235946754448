import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import usePageVisibility from '../utils/hooks/usePageVisibility';

// convert milliseconds to M:SS
function milliToMinSec(ms) {
  let date = new Date(ms);
  return `${date.getUTCMinutes()}:${
    date.getUTCSeconds() < 10 ? '0' : ''
  }${date.getUTCSeconds()}`;
}

function AuthExpirationModal({ open, onClose, milliseconds }) {
  const timeRef = useRef(milliseconds);
  const [countdown, setCountdown] = useState(milliToMinSec(timeRef.current));
  const countdownIntervalRef = useRef(null);
  const isVisible = usePageVisibility();

  useEffect(() => {
    return () => {
      stopCountdown();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (open) {
      startCountdown();
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (!isVisible && open) {
      stopCountdown();
      onClose('logout');
    }
    // eslint-disable-next-line
  }, [isVisible]);

  const startCountdown = () => {
    countdownIntervalRef.current = setInterval(() => {
      // This runs every second.
      // Check to see if we are out of time
      if (timeRef.current - 1000 < 0) {
        // Logout
        stopCountdown();
        onClose('logout');
      } else {
        // Otherwise Subtract A Second
        timeRef.current -= 1000;
        setCountdown(milliToMinSec(timeRef.current));
      }
    }, 1000);
  };

  const stopCountdown = () => {
    // Clear Countdown Interval
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
    // Reset Time Ref and Countdown
    if (timeRef.current) {
      timeRef.current = milliseconds;
      setCountdown(milliToMinSec(timeRef.current));
    }
  };

  return (
    <Modal isOpen={open} onClosed={onClose} centered>
      <ModalHeader className="text-bg-cpyellow-500">
        <FontAwesomeIcon icon={faTriangleExclamation} />{' '}
        <span className="ms-2">Session Expiration</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>For security reasons, your session is about to expire...</p>
            <h3 className="text-center">{countdown}</h3>
            <p>Would you like to continue working?</p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <Button
              color="cpblue-500"
              block
              onClick={() => {
                onClose('refresh');
                stopCountdown();
              }}
            >
              Continue
            </Button>
            <Button
              className="mt-2"
              color="cpblue-100"
              block
              onClick={() => {
                onClose('logout');
                stopCountdown();
              }}
            >
              Logout Now
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default AuthExpirationModal;
