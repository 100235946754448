import React from 'react';
import { Col, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { useField } from 'formik';

function BootstrapToggleInput({
  label,
  labelChecked,
  labelUnchecked,
  fullWidth = false,
  ...props
}) {
  const [field, meta] = useField({ name: props.name, type: 'checkbox' });

  return (
    <Col className="mb-3" md={fullWidth ? '12' : ''}>
      <p className="mb-2 p-0">{label}</p>
      <FormGroup switch>
        <Input
          className="form-check-input"
          type="checkbox"
          role="switch"
          {...field}
          {...props}
        />
        <Label htmlFor={props.id || props.name} check>
          {field.checked ? labelChecked : labelUnchecked}
        </Label>
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default BootstrapToggleInput;
