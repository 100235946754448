import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroadcastTower } from '@fortawesome/pro-regular-svg-icons';
import { getDefaultBroadcastName } from '../utils/dateTimeFormat';
import { _BROADCAST } from '../utils/empties';
import { useUser } from '../utils/contexts/userContext';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../utils/hooks/reactQuery/mutations';
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

const NewBroadcastModal = ({ open, setOpen }) => {
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [broadcastName, setBroadcastName] = useState('');
  const { data: broadcastTemplates = [] } = useAllQuery('broadcast-templates');
  const { add } = useItemMutation('broadcast');
  const { user: loggedUser } = useUser();
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [itemCodes, setItemCodes] = useState([]);

  useEffect(() => {
    if (open) {
      if (selectedCode) {
        setBroadcastName(getDefaultBroadcastName(selectedCode));
      }
      setSelectedTemplate(null);
    } else {
      setSelectedCode(null);
      setSelectedTemplate(null);
      setBroadcastName('');
    }
  }, [selectedCode, open]);

  useEffect(() => {
    filterTemplates(broadcastTemplates);
    // eslint-disable-next-line
  }, [broadcastTemplates, loggedUser]);

  const setItemCodeArray = (templates) => {
    const itemCodes = new Set();
    templates.forEach((template) => {
      itemCodes.add(template.itemCode);
    });
    const sortedItemCodes = Array.from(itemCodes).sort();
    setItemCodes(sortedItemCodes);
  };

  const filterTemplates = (templates) => {
    // We need to only display templates belonging to the logged user's groups
    let filteredTemplates = [
      ...templates.sort((a, b) => (a.name > b.name ? 1 : -1)),
    ];
    if (loggedUser && loggedUser.userGroups?.length > 0) {
      const userGroupIds = loggedUser.userGroups.map((group) => group._id);
      filteredTemplates = filteredTemplates.filter(
        (template) =>
          template.userGroups.some((id) => userGroupIds.includes(id)) &&
          template.emailEnabled,
      );
      setFilteredTemplates(filteredTemplates);
      setItemCodeArray(filteredTemplates);
    } else {
      setFilteredTemplates([]);
      setItemCodeArray([]);
    }
  };

  const createNewBroadcast = () => {
    let newTemplate = {
      ..._BROADCAST,
      name: broadcastName,
      broadcastTemplate: selectedTemplate._id,
      tags: selectedTemplate.tags,
    };
    console.log('New Template: ', JSON.stringify(newTemplate, null, 2));
    add.mutate(newTemplate);
  };

  return (
    <Modal isOpen={open} toggle={() => setOpen(false)} centered>
      <ModalHeader className="text-bg-cpblue-500">
        <FontAwesomeIcon icon={faBroadcastTower} />{' '}
        <span className="ms-2">Create Broadcast</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>To get started, please select an Item Code below.</p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <FormGroup>
              <Label for="item-code">Item Code</Label>
              <Typeahead
                filterBy={(option, props) => {
                  if (props.selected && props.selected.length) {
                    return true;
                  } else {
                    return (
                      option.toLowerCase().indexOf(props.text.toLowerCase()) !==
                      -1
                    );
                  }
                }}
                id="item-code"
                name="item-code"
                placeholder="Item Code"
                options={itemCodes}
                onChange={(selected) => setSelectedCode(selected[0] || null)}
                selected={selectedCode ? [selectedCode] : []}
              />
            </FormGroup>
          </Col>
        </Row>
        {selectedCode && (
          <Row>
            <Col>
              <FormGroup>
                <Label for="broadcast-template">Broadcast Template</Label>
                <Typeahead
                  filterBy={(option, props) => {
                    if (props.selected && props.selected.length) {
                      return true;
                    } else {
                      return (
                        option[props.labelKey]
                          .toLowerCase()
                          .indexOf(props.text.toLowerCase()) !== -1
                      );
                    }
                  }}
                  id="broadcast-template"
                  name="broadcast-template"
                  placeholder="Broadcast Template"
                  labelKey="name"
                  options={filteredTemplates.filter(
                    (template) => template.itemCode === selectedCode,
                  )}
                  onChange={(selected) =>
                    setSelectedTemplate(selected[0] || null)
                  }
                  selected={selectedTemplate ? [selectedTemplate] : []}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {selectedCode && selectedTemplate && (
          <Row>
            <Col>
              <FormGroup>
                <Label for="broadcast-name">Broadcast Name</Label>
                <Input
                  id="broadcast-name"
                  name="broadcast-name"
                  placeholder="Name"
                  value={broadcastName}
                  onChange={(e) => {
                    e.persist();
                    setBroadcastName(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {selectedCode && selectedTemplate && broadcastName && (
          <Row className="pt-3">
            <Col>
              <Button block color="cpblue-500" onClick={createNewBroadcast}>
                Create
              </Button>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export default NewBroadcastModal;
