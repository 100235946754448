import { cloudfrontUrl } from './url';

export function rewriteS3ImageUrls(S3Url) {
  const parts = S3Url.split('/');
  if (parts.length > 0 && cloudfrontUrl) {
    return `${cloudfrontUrl}${parts[4]}/${parts[5]}/${parts[6]}`;
  } else {
    return S3Url;
  }
}
