import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Col, Input, Label, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  FormCard,
  SaveButton,
  AceCodeEditorInput,
  TypeaheadSingleInput,
  TypeaheadTagsInput,
} from '../../components/forms';
import { _TEMPLATE_FILE } from '../../utils/empties';
import { useSingleQuery } from '../../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';

const TemplateFileSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tags: Yup.array().of(Yup.string()),
  type: Yup.string().required('Type is required'),
  content: Yup.string().required('Content is required'),
});

function TemplateFile() {
  const { id } = useParams();
  const [version, setVersion] = useState(undefined);
  const {
    data = _TEMPLATE_FILE,
    isLoading,
    isError,
    error,
  } = useSingleQuery('template-file', id, null, version);
  const { update, add } = useItemMutation('template-file');

  const popVersionSelect = () => {
    if (!data.version) {
      return null;
    }
    const options = Array.from(
      { length: data.version },
      (_, index) => index + 1,
    );
    return options.map((number) => (
      <option key={number} value={number}>
        {number}
      </option>
    ));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving template file...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved template file!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setVersion(data.version && data.version > 0 ? data.version + 1 : 2);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save template file...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          setVersion(1);
          toast.update(toastId, {
            render: 'Saved template file!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save template file...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/template-files"
          breadcrumbLabel="Template Files"
          breadcrumbItem={
            data.name.length > 0 ? data.name : 'New Template File'
          }
          extraInline={
            <Row className="align-items-center justify-items-end g-3">
              <Col>
                <Label className="m-0 float-end" for="versionSelect">
                  Version
                </Label>
              </Col>
              <Col sm={3}>
                <Input
                  bsSize="sm"
                  type="select"
                  name="versionSelect"
                  id="versionSelect"
                  value={version || data.version}
                  onChange={(e) => setVersion(e.target.value)}
                >
                  {popVersionSelect()}
                </Input>
              </Col>
            </Row>
          }
        >
          <Formik
            initialValues={data}
            validationSchema={TemplateFileSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                  <TypeaheadSingleInput
                    fullWidth
                    label="Type"
                    id="type"
                    name="type"
                    placeholder="Type"
                    options={['Email', 'SMS']}
                  />
                  <AceCodeEditorInput
                    label="Content"
                    id="content"
                    name="content"
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default TemplateFile;
