import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useBreakpoint from '../utils/hooks/useBreakpoint';

function ResponsiveIconSubmitButton({ disabled, icon, children }) {
  const breakpoint = useBreakpoint();
  return (
    <Button
      type="submit"
      color="cpblue-500"
      disabled={disabled}
      className={`${
        breakpoint === 'xs' || breakpoint === 'sm' ? 'w-100' : 'float-end'
      }`}
    >
      <FontAwesomeIcon icon={icon} />
      <span className="ms-2">{children}</span>
    </Button>
  );
}

export default ResponsiveIconSubmitButton;
