import React, { useReducer } from 'react';
import {
  faBackward,
  faCopy,
  faShuffle,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-searchbox';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Draftail } from '../components/draftail';
import { FormCard } from '../components/forms';
import useBreakpoint from '../utils/hooks/useBreakpoint';
import {
  entitiesToCharacters,
  formatHtml,
  htmlToEmail,
} from '../utils/htmlParse';
import { useAllQuery } from '../utils/hooks/reactQuery/queries';
import { apiUrl } from '../utils/url';
import { getJwt } from '../utils/jwt';

const initialState = {
  web: '<p>Paste your Word document here.</p>',
  email: '',
  listCode: '',
  generateKeywords: false,
  draftailKeyModifier: 0,
  pageShown: 1,
  fetchingKeywords: false,
  webCopied: false,
  emailCopied: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_KEYWORDS':
      return {
        ...state,
        generateKeywords: !state.generateKeywords,
      };
    case 'UPDATE_WEB':
      return {
        ...state,
        web: action.payload,
      };
    case 'UPDATE_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'UPDATE_LIST_CODE':
      return {
        ...state,
        listCode: action.payload,
      };
    case 'UPDATE_DRAFTAIL_KEY_MODIFIER':
      return {
        ...state,
        draftailKeyModifier: state.draftailKeyModifier + 1,
      };
    case 'UPDATE_PAGE_SHOWN':
      return {
        ...state,
        pageShown: action.payload,
      };
    case 'TOGGLE_FETCHING_KEYWORDS':
      return {
        ...state,
        fetchingKeywords: !state.fetchingKeywords,
      };
    case 'TOGGLE_WEB_COPIED':
      return {
        ...state,
        webCopied: !state.webCopied,
      };
    case 'TOGGLE_EMAIL_COPIED':
      return {
        ...state,
        emailCopied: !state.emailCopied,
      };
    case 'RESET_COPIED':
      return {
        ...state,
        webCopied: false,
        emailCopied: false,
      };
    default:
      return state;
  }
};

function PastaDocs() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const breakpoint = useBreakpoint();
  const {
    data: listCodes = [
      'INVESTME',
      'LIBWEALT',
      'WEALTHRE',
      'CLUBNEWS',
      'TRADEDAY',
      'TDWATCH',
      'MANDIGES',
      'BRK',
      'OXF',
      'MWL',
      'MWTACTIC',
      'OXC',
    ],
  } = useAllQuery('decision-vision-list-codes');

  const handleConvert = async () => {
    if (state.generateKeywords) {
      const toastId = toast.loading('Generating Keywords...');
      dispatch({ type: 'TOGGLE_FETCHING_KEYWORDS' });
      try {
        const { data } = await axios.post(
          `${apiUrl}decision-vision/get-keywords`,
          {
            list: state.listCode,
            content: state.web || '',
          },
          {
            headers: {
              Authorization: `Bearer ${getJwt()}`,
            },
          },
        );
        if (data.content) {
          toast.update(toastId, {
            render: 'Fetched keyword links!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          dispatch({
            type: 'UPDATE_EMAIL',
            payload: htmlToEmail(data.content),
          });
          dispatch({ type: 'UPDATE_PAGE_SHOWN', payload: 2 });
          dispatch({ type: 'TOGGLE_FETCHING_KEYWORDS' });
        }
      } catch (error) {
        console.error(error);
        toast.update(toastId, {
          render: 'Error fetching keywords...',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
        dispatch({ type: 'TOGGLE_FETCHING_KEYWORDS' });
      }
    } else {
      dispatch({ type: 'UPDATE_EMAIL', payload: htmlToEmail(state.web) });
      dispatch({ type: 'UPDATE_PAGE_SHOWN', payload: 2 });
    }
  };

  if (state.pageShown === 1) {
    return (
      <Container fluid>
        <FormCard
          breadcrumbLink="/dashboard"
          breadcrumbLabel="Dashboard"
          breadcrumbItem="Pasta Docs"
        >
          <Row>
            <Col>
              <Draftail
                key={`draftail-editor-${state.draftailKeyModifier}`}
                initialHtml={
                  state.web || '<p>Paste your Word document here.</p>'
                }
                setHtml={(html) =>
                  dispatch({
                    type: 'UPDATE_WEB',
                    payload: entitiesToCharacters(formatHtml(html)),
                  })
                }
                showTopToolbar={true}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="mb-3" md="12">
              <p className="mb-2 p-0">Generate Keywords</p>
              <FormGroup switch>
                <Input
                  id="generate-keywords"
                  name="generate-keywords"
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={state.generateKeywords}
                  onChange={() => dispatch({ type: 'TOGGLE_KEYWORDS' })}
                />
                <Label htmlFor="generate-keywords" check>
                  {state.generateKeywords
                    ? 'This will generate keywords'
                    : 'This will not generate keywords'}
                </Label>
              </FormGroup>
            </Col>
            <Col className={state.generateKeywords ? 'd-block' : 'd-none'}>
              <FormGroup>
                <Label for="list-code">List Code</Label>
                <Input
                  type="select"
                  name="list-code"
                  id="list-code"
                  placeholder="List Code"
                  value={state.listCode}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_LIST_CODE',
                      payload: e.target.value,
                    })
                  }
                >
                  {listCodes
                    .sort((a, b) => (a < b ? -1 : 1))
                    .map((listCode) => (
                      <option key={listCode} value={listCode}>
                        {listCode}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button
            color="cpblue-500"
            className={`${
              breakpoint === 'xs' || breakpoint === 'sm' ? 'w-100' : 'float-end'
            }`}
            disabled={
              state.web === '' ||
              (state.generateKeywords && state.listCode === '')
            }
            onClick={handleConvert}
          >
            <FontAwesomeIcon icon={faShuffle} />{' '}
            <span className="ms-2">Convert</span>
          </Button>
        </FormCard>
      </Container>
    );
  } else {
    return (
      <Container fluid>
        <FormCard
          breadcrumbLink="/dashboard"
          breadcrumbLabel="Dashboard"
          breadcrumbItem="Pasta Docs"
        >
          <Row className="mb-3">
            <Col md="6">
              <Row className="d-flex flex-row align-items-center">
                <Col md="6" className="mb-3">
                  <h4 className="m-0 p-0">Web Content</h4>
                </Col>
                <Col className="mb-3">
                  <Button
                    color={state.webCopied ? 'cpblue-800' : 'cpblue-500'}
                    className={`${
                      breakpoint === 'xs' || breakpoint === 'sm'
                        ? 'w-100'
                        : 'float-end'
                    }`}
                    onClick={() => {
                      if (!state.webCopied) {
                        dispatch({ type: 'TOGGLE_WEB_COPIED' });
                      }
                      navigator.clipboard.writeText(state.web);
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />{' '}
                    <span className="ms-2">
                      {state.webCopied ? 'Copied!' : 'Copy'}
                    </span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AceEditor
                    fontSize={16}
                    showPrintMargin={false}
                    wrapEnabled={true}
                    width="100%"
                    mode="html"
                    theme={'tomorrow'}
                    name="web-content-ace-editor"
                    editorProps={{ $blockScrolling: true }}
                    value={state.web || ''}
                    readOnly={true}
                    setOptions={{
                      indentedSoftWrap: false,
                      printMarginColumn: 90,
                      useWorker: false,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <hr
              className={
                breakpoint === 'xs' || breakpoint === 'sm' ? 'my-3' : 'd-none'
              }
            />
            <Col>
              <Row className="d-flex flex-row align-items-center">
                <Col md="6" className="mb-3">
                  <h4 className="m-0 p-0">Email Content</h4>
                </Col>
                <Col className="mb-3">
                  <Button
                    color={state.emailCopied ? 'cpblue-800' : 'cpblue-500'}
                    className={`${
                      breakpoint === 'xs' || breakpoint === 'sm'
                        ? 'w-100'
                        : 'float-end'
                    }`}
                    onClick={() => {
                      if (!state.emailCopied) {
                        dispatch({ type: 'TOGGLE_EMAIL_COPIED' });
                      }
                      navigator.clipboard.writeText(state.email);
                    }}
                  >
                    <FontAwesomeIcon icon={faCopy} />{' '}
                    <span className="ms-2">
                      {state.emailCopied ? 'Copied!' : 'Copy'}
                    </span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AceEditor
                    fontSize={16}
                    showPrintMargin={false}
                    wrapEnabled={true}
                    width="100%"
                    mode="html"
                    theme={'tomorrow'}
                    name="email-content-ace-editor"
                    editorProps={{ $blockScrolling: true }}
                    value={state.email || ''}
                    readOnly={true}
                    setOptions={{
                      indentedSoftWrap: false,
                      printMarginColumn: 90,
                      useWorker: false,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Button
            color="cpblue-500"
            className={`${
              breakpoint === 'xs' || breakpoint === 'sm'
                ? 'w-100'
                : 'float-start'
            }`}
            onClick={() => {
              dispatch({ type: 'UPDATE_PAGE_SHOWN', payload: 1 });
              dispatch({ type: 'RESET_COPIED' });
            }}
          >
            <FontAwesomeIcon icon={faBackward} />{' '}
            <span className="ms-2">Back</span>
          </Button>
        </FormCard>
      </Container>
    );
  }
}

export default PastaDocs;
