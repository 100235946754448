import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import {
  BootstrapTextInput,
  SaveButton,
  TypeaheadTagsInput,
  FormCard,
} from '../../components/forms';
import { _PLACEHOLDER_MAPPING } from '../../utils/empties';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import { useSingleQuery } from '../../utils/hooks/reactQuery/queries';

const PlaceholderMappingSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  tags: Yup.array().of(Yup.string()),
  key: Yup.string().required('Key is required'),
  value: Yup.string().required('Value is required'),
});

function PlaceholderMapping() {
  const { id } = useParams();
  const {
    data = _PLACEHOLDER_MAPPING,
    isLoading,
    isError,
    error,
  } = useSingleQuery('placeholder-mapping', id);
  const { update, add } = useItemMutation('placeholder-mapping');

  const handleSubmit = (values, { setSubmitting }) => {
    const toastId = toast.loading('Saving placeholder mapping...');

    // Are we updating or adding?
    if (id !== 'new') {
      update.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Updated!');
          toast.update(toastId, {
            render: 'Saved placeholder mapping!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save placeholder mapping...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    } else {
      add.mutate(values, {
        onSuccess: () => {
          console.log('Successfully Created!');
          toast.update(toastId, {
            render: 'Saved placeholder mapping!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        },
        onError: (error) => {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not save placeholder mapping...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setSubmitting(false);
        },
      });
    }
  };

  return (
    <Container fluid>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : isError ? (
        <h5>Error: {error.message}</h5>
      ) : (
        <FormCard
          breadcrumbLink="/dashboard/placeholder-mappings"
          breadcrumbLabel="Placeholder Mappings"
          breadcrumbItem={
            data.name.length > 0 ? data.name : 'New Placeholder Mapping'
          }
        >
          <Formik
            initialValues={data}
            validationSchema={PlaceholderMappingSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isValid, isSubmitting, values, errors }) => (
              <Form>
                <Row>
                  <BootstrapTextInput
                    label="Name"
                    id="name"
                    name="name"
                    placeholder="Name"
                  />
                  <TypeaheadTagsInput />
                  <BootstrapTextInput
                    label="Key"
                    id="key"
                    name="key"
                    placeholder="Key"
                  />
                  <BootstrapTextInput
                    label="Value"
                    id="value"
                    name="value"
                    placeholder="Value"
                  />
                </Row>
                <SaveButton disabled={isSubmitting || !isValid} />
              </Form>
            )}
          </Formik>
        </FormCard>
      )}
    </Container>
  );
}

export default PlaceholderMapping;
