import React, { useState } from 'react';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  FormText,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { AtomicBlockUtils } from 'draft-js';
import { clearEmptyBlocks } from 'draft-regex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { ImageUploadInput } from '../forms';

function ImageSource(props) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [alt, setAlt] = useState('');
  const [link, setLink] = useState('');
  const [src, setSrc] = useState('');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);

    const { editorState, onComplete } = props;

    onComplete(editorState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { editorState, entityType, onComplete } = props;

    if (src !== '') {
      const content = editorState.getCurrentContent();
      const contentWithEntity = content.createEntity(
        entityType.type,
        'IMMUTABLE',
        { src, alt, link },
      );
      const entityKey = contentWithEntity.getLastCreatedEntityKey();
      const nextState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' ',
      );

      onComplete(clearEmptyBlocks(nextState, 0));
    } else {
      onComplete(editorState);
    }
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalHeader className="text-bg-cpblue-500">
        <FontAwesomeIcon icon={faImage} />{' '}
        <span className="ms-2">Insert Image</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row>
                <ImageUploadInput
                  id="image-file"
                  name="image-file"
                  label="Image"
                  placeholder="Image"
                  value={src}
                  onChange={(value) => setSrc(value)}
                >
                  <FormText color="muted">
                    Please provide a valid image url or upload an image file.
                  </FormText>
                </ImageUploadInput>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="image-alt">Image Alt</Label>
                    <Input
                      type="text"
                      id="image-alt"
                      name="image-alt"
                      placeholder="Image Alt"
                      src={alt}
                      onChange={(e) => setAlt(e.target.value)}
                    />
                    <FormText color="muted">
                      Please provide alt text for the image.
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="image-link">Image Link</Label>
                    <Input
                      type="text"
                      id="image-link"
                      name="image-link"
                      placeholder="Image Link"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                    <FormText color="muted">
                      Optionally provide a hyperlink for the image.
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button block color="cpblue-500" type="submit">
                    Insert
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default ImageSource;
