export const buildSmsTemplate = (
  template,
  options,
  fromName = '',
  emailTemplateUuid = '',
  itemCode = '',
  webPostPermalink = '',
) => {
  // First let's grab our current template
  let smsTemplate = template;

  // Static values we always try to inject
  smsTemplate = smsTemplate.replace('$friendly-from', fromName);
  smsTemplate = smsTemplate.replace('$email-template-uuid', emailTemplateUuid);
  smsTemplate = smsTemplate.replace('$item-code', itemCode);
  smsTemplate = smsTemplate.replace('$web-post-permalink', webPostPermalink);

  // Next we will iterate through all of our options and place their values in the template
  for (let option of options) {
    // look for $option-key and replace with option.value
    smsTemplate = smsTemplate.replace(`$${option.key}`, option.value);
  }

  // Done! Let's return our template complete with all options and content!
  return smsTemplate;
};
