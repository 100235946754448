import React, { useEffect, useRef } from 'react';
import { Col, FormGroup, FormFeedback, Label } from 'reactstrap';
import { useField } from 'formik';
import { Draftail } from '../draftail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';

function DraftailRichTextInput({ label, required = false, ...props }) {
  const [{ value }, { error, touched }, { setValue, setTouched }] =
    useField(props);

  const DraftailEditorRef = useRef(null);
  useEffect(() => {
    const thisElement = document.querySelector(`.this-${props.id}`);
    DraftailEditorRef.current = thisElement.querySelector('.Draftail-Editor');
  }, []);

  useEffect(() => {
    if (error && touched && DraftailEditorRef.current) {
      DraftailEditorRef.current.classList.add('is-invalid', 'form-control');
    } else {
      DraftailEditorRef.current.classList.remove('is-invalid', 'form-control');
    }
  });

  return (
    <Col className={`this-${props.id}`} md="12">
      <FormGroup>
        <Label htmlFor={props.id || props.name}>
          {required && (
            <span className="text-cpred-500 me-2">
              <FontAwesomeIcon icon={faAsterisk} size="2xs" />
            </span>
          )}
          {label}
        </Label>
        <Draftail
          initialHtml={value || ''}
          setHtml={(html) => setValue(html, true)}
          showTopToolbar={true}
          onBlur={() => {
            setTouched(true, true);
          }}
          {...props}
        />
        <FormFeedback>{error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default DraftailRichTextInput;
