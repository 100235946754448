import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import CroppieComponent from '../components/CroppieComponent';
import axios from 'axios';
import * as jwt from '../utils/jwt';
import { apiUrl } from '../utils/url';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faUpload,
  faCropSimple,
  faCheck,
  faCopy,
} from '@fortawesome/pro-duotone-svg-icons';
import { FormCard } from '../components/forms';

function HeroCrop() {
  // Image URL
  const [imageUrl, setImageUrl] = useState(null);
  // Image Name
  const [imageName, setImageName] = useState(null);
  // Base 64 Image
  const [blob, setBlob] = useState(null);
  // Modified Name
  const [newName, setNewName] = useState(null);
  // New Image URL
  const [newUrl, setNewUrl] = useState(null);
  // Uploading state
  const [isUploading, setIsUploading] = useState(false);
  // Modal State
  const [isOpen, setIsOpen] = useState(false);
  // Whether Content Was Copied With Button
  const [copied, setCopied] = useState(false);

  // Toggle Modal State
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  // On Image Input Change
  const onImageSelect = (e) => {
    // Get the selected file
    const selectedFile = e.target.files[0];
    // make sure it is an image
    if (selectedFile.type.includes('image')) {
      // Get the data url
      let reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    }
    // Get the name
    setImageName(selectedFile.name);
  };

  // Whenever Croppie crops
  const onCrop = (base64, newFileName) => {
    setBlob(base64);
    setNewName(newFileName);
  };

  // On Upload
  const onUpload = () => {
    setIsUploading(true);
    setCopied(false);
    const toastId = toast.loading('Uploading image...');

    // Get our File
    let file = blobToFile();

    // Make sure we have a file
    if (file) {
      // Grab our Auth Token
      const myJwt = jwt.getJwt();
      // Configure Headers
      const headers = { headers: { Authorization: `Bearer ${myJwt}` } };
      // New Form Data
      const formData = new FormData();
      formData.append('image', file);
      formData.append('bucketName', 'assets.oxfordclub.com');
      formData.append('path', 'emails/images/');
      // POST to files/uploadImage endpoint
      axios
        .post(`${apiUrl}s3-images/upload`, formData, headers)
        .then((res) => {
          setIsUploading(false);
          toast.update(toastId, {
            render: 'Image uploaded!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          setNewUrl(res.data.Location);
          setIsOpen(true);
        })
        .catch((err) => {
          console.error('Upload Error', err);
          toast.update(toastId, {
            render: 'Failed to upload image...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          setIsUploading(false);
        });
    }
  };

  // Convert our Blob to a File
  const blobToFile = () => {
    if (blob) {
      let ext = newName.split('.')[1];
      return new File([blob], newName, {
        type: `image/${ext === 'jpg' ? 'jpeg' : ext}`,
      });
    }

    return null;
  };

  // Create a text area with our content and then copy it to clipboard
  // and then remove text area
  const copyContent = () => {
    let textArea = document.createElement('textarea');
    textArea.value = newUrl;
    textArea.setAttribute('readonly', '');
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
  };

  const popPreview = () => {
    if (blob) {
      return (
        <>
          <Row>
            <Col>
              <h3>Preview</h3>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <img
                className="img-fluid mx-auto d-block"
                src={URL.createObjectURL(blob)}
                alt="Crop Preview"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button color="cpblue-500" block onClick={onUpload}>
                <FontAwesomeIcon
                  icon={isUploading ? faCog : faUpload}
                  spin={isUploading}
                />{' '}
                Upload
              </Button>
            </Col>
          </Row>
        </>
      );
    }

    return <div />;
  };

  return (
    <Container fluid>
      <FormCard
        breadcrumbLink="/dashboard"
        breadcrumbLabel="Dashboard"
        breadcrumbItem="Hero Crop"
      >
        <Row>
          <Col>
            <Form>
              <FormGroup>
                <Label for="imageFile">Image</Label>
                <Input
                  type="file"
                  onChange={onImageSelect}
                  className="form-control"
                  name="imageFile"
                  id="imageFile"
                  label="Choose image"
                  accept={'image/*'}
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <CroppieComponent
              // key={croppieKey}
              imageUrl={imageUrl}
              imageName={imageName}
              setBlob={onCrop}
            />
          </Col>
          <Col>{popPreview()}</Col>
        </Row>
      </FormCard>
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader className="text-white bg-cpblue-500">
          <FontAwesomeIcon icon={faCropSimple} /> Uploaded Image URL
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="generatedLink">Image URL</Label>
            <InputGroup>
              <Input type="text" value={newUrl} />
              <Button
                color={copied ? 'cpblue-300' : 'cpblue-500'}
                onClick={copyContent}
              >
                <FontAwesomeIcon icon={copied ? faCheck : faCopy} />{' '}
                {copied ? 'Copied' : 'Copy'}
              </Button>
            </InputGroup>
          </FormGroup>
        </ModalBody>
      </Modal>
    </Container>
  );
}

export default HeroCrop;
