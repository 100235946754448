import React, { useEffect, useState } from 'react';
import { Draftail } from '../../../components/draftail';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-searchbox';
import { Button, Col, List, ListInlineItem, Row } from 'reactstrap';

function WebPost({
  initialHtml,
  setHtml,
  externalKey,
  testWebPost,
  webPostStatus,
  launched,
}) {
  // State for our HTML while in this component
  const [internalHtml, setInternalHtml] = useState(initialHtml);
  // Modifier To Force Editor Render
  const [keyModifier, setKeyModifier] = useState(0);
  // Code or Rich Text Editor
  const [codeEditor, setCodeEditor] = useState(false);
  // Web Draft Links
  const [webDraftLinks, setWebDraftLinks] = useState([]);

  useEffect(() => {
    if (webPostStatus) {
      const statusKeys = Object.keys(webPostStatus);
      if (statusKeys.length > 0) {
        const links = statusKeys.map((key) => webPostStatus[key].link);
        setWebDraftLinks(links);
        console.log('Web Draft Links', links);
      }
    }
  }, [webPostStatus]);

  const updateHtml = (html) => {
    setHtml(html);
    setInternalHtml(html);
  };

  return (
    <Col>
      <Row>
        <Col>
          <p>Changes made here will be reflected on the web only...</p>
        </Col>
      </Row>
      <Row className="mb-3 d-flex align-items-center">
        {webDraftLinks.length > 0 && (
          <Col>
            <List className="mb-0" type="inline">
              <ListInlineItem>Web Drafts:</ListInlineItem>
              {webDraftLinks.map((link) => (
                <ListInlineItem key={link}>
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                </ListInlineItem>
              ))}
            </List>
          </Col>
        )}
        <Col>
          <Button
            className="float-end ms-2"
            onClick={() => {
              setCodeEditor(!codeEditor);

              if (codeEditor) {
                setKeyModifier((old) => old + 1);
              }
            }}
            color="cpblue-300"
          >
            {codeEditor ? 'Edit Rich Text' : 'Edit HMTL'}
          </Button>
          <Button
            onClick={testWebPost}
            color="cpblue-500"
            className="float-end"
            disabled={launched}
          >
            {webDraftLinks.length === 0 ? 'Test Web Post' : 'Update Web Post'}
          </Button>
        </Col>
      </Row>
      {codeEditor ? (
        <AceEditor
          fontSize={16}
          showPrintMargin={false}
          wrapEnabled={true}
          width="100%"
          mode="html"
          theme={'tomorrow'}
          name="email-content-ace-editor"
          editorProps={{ $blockScrolling: true }}
          value={internalHtml}
          onChange={(html) => updateHtml(html)}
          setOptions={{
            indentedSoftWrap: false,
            printMarginColumn: 90,
            useWorker: false,
          }}
        />
      ) : (
        <Draftail
          key={`rich-web-editor-${keyModifier}-${externalKey}`}
          initialHtml={internalHtml}
          setHtml={(html) => updateHtml(html)}
          showTopToolbar={true}
        />
      )}
    </Col>
  );
}

export default WebPost;
