import React from 'react';
import { stringToColor } from '../utils/colors';

function getTextColor(hexColor) {
  // Convert hex color code to RGB
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);

  // Calculate relative luminance of the color
  const luminance = (0.2126 * red + 0.7152 * green + 0.0722 * blue) / 255;

  // Use a threshold value to determine whether the color is light or dark
  if (luminance > 0.5) {
    // Color is light, use dark text
    return 'dark';
  } else {
    // Color is dark, use light text
    return 'light';
  }
}

function getInitials(firstName, lastName) {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
}

function UserAvatar({ user, size = 46, children }) {
  const { firstName, lastName, profileImage } = user;
  const initials = getInitials(firstName, lastName);
  const fontSize = Math.floor(size * 0.4);
  const bgcolor = stringToColor(firstName + lastName);
  const textcolor = getTextColor(bgcolor);
  const styles = {
    width: size,
    height: size,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: bgcolor,
    color: textcolor === 'dark' ? '#000' : '#fff',
    fontSize: fontSize,
  };

  const popAvatar = () =>
    profileImage && profileImage.length > 0 ? (
      <img
        alt={`${firstName.charAt(0).toUpperCase()}${lastName
          .charAt(0)
          .toUpperCase()}`}
        className="rounded-circle"
        width={size}
        height={size}
        src={profileImage}
      />
    ) : (
      <div style={styles}>
        {`${firstName.charAt(0).toUpperCase()}${lastName
          .charAt(0)
          .toUpperCase()}`}
      </div>
    );

  return children
    ? children(popAvatar(), profileImage && profileImage.length > 0)
    : popAvatar();
}

export default UserAvatar;
