import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faDash } from '@fortawesome/pro-solid-svg-icons';

export default function InUse({ data }) {
  if (!data || data.length < 1) {
    return (
      <FontAwesomeIcon icon={faDash} size="xl" className="text-secondary" />
    );
  }

  return (
    <FontAwesomeIcon icon={faCheck} size="xl" className="text-cpblue-500" />
  );
}
