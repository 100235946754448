import React, { useEffect } from 'react';
import { Col, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { useField } from 'formik';

function BootstrapDateTimeInput({
  label,
  value = null,
  fullWidth = false,
  ...props
}) {
  const [field, meta, { setValue }] = useField(props);

  useEffect(() => {
    if (value) {
      setValue(value, true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Col md={fullWidth ? '12' : '6'}>
      <FormGroup>
        <Label htmlFor={props.id || props.name}>{label}</Label>
        <Input
          type="datetime-local"
          invalid={meta.touched && meta.error ? true : false}
          {...field}
          {...props}
        />
        <FormFeedback>{meta.error}</FormFeedback>
      </FormGroup>
    </Col>
  );
}

export default BootstrapDateTimeInput;
