import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap';
import Croppie from 'croppie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCropSimple, faExpand } from '@fortawesome/pro-duotone-svg-icons';

function CroppieComponent({ imageUrl, imageName, setBlob }) {
  // Our Croppie Instance
  const [croppie, setCroppie] = useState();
  // Selected Size
  const [size, setSize] = useState({ width: 300, height: 300 });

  // When size changes init croppie
  useEffect(() => {
    initCroppie(size.width, size.height, imageUrl);
    // eslint-disable-next-line
  }, [size, imageUrl]);

  const initCroppie = (width, height, url) => {
    // If existing croppie, destroy it
    if (croppie) {
      croppie.destroy();
    }
    // create new croppie instance
    const newCroppie = new Croppie(document.getElementById('croppie'), {
      viewport: { width: width, height: height },
      boundary: { width: 700, height: 500 },
      showZoomer: true,
      enableOrientation: true,
    });
    // Store that instance
    setCroppie(newCroppie);
    // If we have an image url let's bind it to the instance
    if (url) {
      newCroppie.bind({
        url: url,
      });
    }
  };

  // Crop Image
  const crop = () => {
    // Determine size string
    const sizeAsString = `_${size.width}x${size.height}`;
    // Get filename and ext
    let [fileName, ext] = imageName.split('.');
    // Does our filename already contain the size as string?
    let newFileName;
    if (!fileName.includes(sizeAsString)) {
      // Then let's add it
      newFileName = `${fileName}${sizeAsString}.${ext}`;
    }
    // now we can give the base 64 of our crop
    croppie
      .result({
        type: 'blob',
        format: ext === 'jpg' ? 'jpeg' : ext,
      })
      .then((res) => {
        setBlob(res, newFileName);
      });
  };

  // Return our Croppie Div
  return (
    <>
      <Row>
        <Col>
          <div id="croppie" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="presets">Presets</Label>
                  <InputGroup>
                    <InputGroupText>
                      <FontAwesomeIcon icon={faExpand} />
                    </InputGroupText>
                    <Input
                      id="presets"
                      name="presets"
                      type="select"
                      onChange={({ target }) => {
                        setSize({
                          width: parseInt(target.value.split('x')[0]),
                          height: parseInt(target.value.split('x')[1]),
                        });
                      }}
                    >
                      <option value="300x300">300x300</option>
                      <option value="500x250">500x250</option>
                      <option value="440x330">440x330</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="width">Width</Label>
                  <Input
                    id="width"
                    name="width"
                    type="number"
                    value={size.width}
                    onChange={({ target }) =>
                      setSize((prev) => ({ ...prev, width: target.value }))
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="height">Height</Label>
                  <Input
                    id="height"
                    name="height"
                    type="number"
                    value={size.height}
                    onChange={({ target }) =>
                      setSize((prev) => ({ ...prev, height: target.value }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button color="cpblue-500" block onClick={crop}>
              <FontAwesomeIcon icon={faCropSimple} /> Crop
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default CroppieComponent;
