import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  FormText,
  Card,
  CardBody,
  Button,
  Input,
  Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faRefresh } from '@fortawesome/pro-duotone-svg-icons';
import { Typeahead } from 'react-bootstrap-typeahead';
import readXlsxFile from 'read-excel-file';
import * as CCWB from '../components/tableBuildTemplates/CCWB';
import * as Basic from '../components/tableBuildTemplates/Basic';
import * as WarRoom from '../components/tableBuildTemplates/WarRoomWinners';
import { toast } from 'react-toastify';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { FormCard } from '../components/forms';

function TableBuilder() {
  // Whether Content Was Copied With Button
  const [copied, setCopied] = useState(false);
  // Rows From Excel
  const [rows, setRows] = useState(null);
  // End Result
  const [product, setProduct] = useState('');
  // Selected Format
  const [selectedFormat, setSelectedFormat] = useState([]);

  // Input Key
  const [inputKey, setInputKey] = useState(0);

  // Create a text area with our content and then copy it to clipboard
  // and then remove text area
  const copyContent = () => {
    let textArea = document.createElement('textarea');
    textArea.value = product;
    textArea.setAttribute('readonly', '');
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
  };

  // Runs on Rows Change to Build Product
  useEffect(() => {
    if (rows && rows.length > 0) {
      // Check which type of table to build
      switch (selectedFormat[0]) {
        case 'Basic':
          setProduct(Basic.build(rows).trim());
          break;
        case 'CCWB - Trade Summary':
          setProduct(CCWB.build(rows).trim());
          break;
        case 'TD - War Room Winners':
          setProduct(WarRoom.build(rows).trim());
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [rows]);

  // Reset Component
  const reset = () => {
    setCopied(false);
    setProduct('');
    setRows(null);
    setSelectedFormat([]);
    setInputKey(inputKey + 1);
  };

  // File Selected Changed
  const onFileSelected = (e) => {
    e.preventDefault();
    const toastId = toast.loading('Building table...');
    // Get the selected file
    const selectedFile = e.target.files[0];
    // Get the Schema based on Selected Format
    let schema;
    switch (selectedFormat[0]) {
      case 'Basic':
        schema = null;
        break;
      case 'CCWB - Trade Summary':
        schema = CCWB.schema;
        break;
      case 'TD - War Room Winners':
        schema = WarRoom.schema;
        break;
      default:
        break;
    }

    const ErrorsDisplay = ({ errors }) => {
      return (
        <div>
          <h4>{errors.length} Error/s</h4>
          <hr />
          {errors.map((error) => (
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Column</th>
                  <th>Error</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{error.row}</td>
                  <td>{error.column}</td>
                  <td>{error.error}</td>
                </tr>
              </tbody>
            </Table>
          ))}
        </div>
      );
    };

    // Read the file
    if (schema) {
      readXlsxFile(selectedFile, { schema: schema }).then(
        ({ rows, errors }) => {
          if (errors.length === 0) {
            toast.update(toastId, {
              render: 'Table built!',
              type: 'success',
              isLoading: false,
              autoClose: 1500,
            });
            setRows(rows);
          } else {
            console.error(errors);
            toast.update(toastId, {
              render: <ErrorsDisplay errors={errors} />,
              type: 'error',
              isLoading: false,
              autoClose: false,
              closeOnClick: true,
            });
          }
        },
      );
    } else {
      readXlsxFile(selectedFile).then((rows) => setRows(rows));
    }
  };

  return (
    <Container fluid>
      <FormCard
        breadcrumbLink="/dashboard"
        breadcrumbLabel="Dashboard"
        breadcrumbItem="Table Builder"
      >
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="tableFormat">Table Format</Label>
                    <Typeahead
                      id="tableFormat"
                      name="tableFormat"
                      onChange={setSelectedFormat}
                      options={[
                        'Basic',
                        'CCWB - Trade Summary',
                        'TD - War Room Winners',
                      ]}
                      placeholder="Choose a table format..."
                      selected={selectedFormat}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="excelFile">Excel File</Label>
                    <Input
                      key={inputKey}
                      type="file"
                      name="excelFile"
                      id="excelFile"
                      accept={'.xlsx'}
                      disabled={selectedFormat.length < 1}
                      onChange={onFileSelected}
                    />
                    <FormText color="muted">
                      Please select Excel file for upload.
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color={copied ? 'cpblue-800' : 'cpblue-500'}
              className="float-end mb-3 d-block ms-2"
              onClick={copyContent}
            >
              <FontAwesomeIcon icon={faCopy} /> {copied ? 'Copied' : 'Copy'}
            </Button>
            <Button
              color="cpyellow-500"
              className="float-end mb-3 d-block"
              onClick={reset}
            >
              <FontAwesomeIcon icon={faRefresh} /> Reset
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody className="p-0">
                <AceEditor
                  id="productHtml"
                  name="productHtml"
                  fontSize={16}
                  showPrintMargin={false}
                  wrapEnabled={true}
                  width="100%"
                  mode="html"
                  theme={'tomorrow'}
                  height="600px"
                  editorProps={{ $blockScrolling: true }}
                  onChange={(value) => {
                    setProduct(value);
                    setCopied(false);
                  }}
                  value={product}
                  setOptions={{
                    indentedSoftWrap: false,
                    printMarginColumn: 90,
                    useWorker: false,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody className="p-0">
                <iframe
                  title="HTML Preview"
                  style={{ border: 'none' }}
                  srcDoc={`<table>${product}</table>`}
                  width="100%"
                  height="590px"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </FormCard>
    </Container>
  );
}

export default TableBuilder;
