import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  Row,
  Col,
  Button,
  ModalBody,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroadcastTower } from '@fortawesome/pro-regular-svg-icons';

function LaunchBroadcastModal({
  open,
  onClose,
  emailEnabled,
  smsEnabled,
  webEnabled,
}) {
  const [datetime, setDatetime] = useState();
  const [checked, setChecked] = useState({
    email: emailEnabled,
    sms: smsEnabled,
    web: webEnabled,
  });

  useEffect(() => {
    if (open) {
      setDatetime(null);
      setChecked({
        email: emailEnabled,
        sms: smsEnabled,
        web: webEnabled,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Modal isOpen={open} toggle={() => onClose(null, 'cancel')}>
      <ModalHeader className="text-bg-cpyellow-500">
        <FontAwesomeIcon icon={faBroadcastTower} />{' '}
        <span className="ms-2">Launch Broadcast</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <p>When would you like to send this broadcast?</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="datetime">Schedule</Label>
              <Input
                id="datetime"
                name="datetime"
                type="datetime-local"
                value={datetime}
                onChange={(e) => setDatetime(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Which of these should we send?</p>
          </Col>
        </Row>
        <Row>
          <Col>
            {emailEnabled && (
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  checked={checked.email}
                  onChange={(e) => {
                    e.persist();
                    setChecked((old) => ({ ...old, email: e.target.checked }));
                  }}
                  disabled
                />
                <Label check>Email</Label>
              </FormGroup>
            )}
            {smsEnabled && (
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  checked={checked.sms}
                  onChange={(e) => {
                    e.persist();
                    setChecked((old) => ({ ...old, sms: e.target.checked }));
                  }}
                />
                <Label check>SMS</Label>
              </FormGroup>
            )}
            {webEnabled && (
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  checked={checked.web}
                  onChange={(e) => {
                    e.persist();
                    setChecked((old) => ({ ...old, web: e.target.checked }));
                  }}
                />
                <Label check>Web</Label>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            <Button
              disabled={datetime === null}
              color="cpblue-500"
              block
              onClick={() => {
                onClose(
                  { datetime: new Date(datetime).toISOString(), checked },
                  'send',
                );
              }}
            >
              Launch
            </Button>
            <Button
              className="mt-2"
              color="cpblue-100"
              block
              onClick={() => {
                onClose(null, 'cancel');
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default LaunchBroadcastModal;
