export const schema = {
  Type: {
    prop: 'type',
    type: String,
    required: true,
  },
  Company: {
    prop: 'company',
    type: String,
    required: true,
  },
  Trade: {
    prop: 'trade',
    type: String,
    required: true,
  },
  Service: {
    prop: 'service',
    type: String,
    required: true,
  },
  Link: {
    prop: 'link',
    type: (value) => {
      const url = new URL(value);

      if (!url) {
        throw new Error('invalid');
      }

      return url;
    },
    required: true,
  },
};

const template = `<tr><td style="border-collapse: collapse !important; text-align: left; color: #222222; font-family: 'Helvetica', 'Arial', sans-serif; font-weight: normal; line-height: 21px; font-size: 16px; margin: 0; padding: 0;">\n<span style="color: $typeColor;font-weight: bold;">$type</span> $trade\n<br>\n<a href="$link" style="color: #00f; font-size: 14px; text-decoration: none;">$service</a>\n</td></tr>\n<tr><td bgcolor="#ffffff" style="background: #ffffff; font-size: 16px; line-height: 19px; Margin: 0; padding: 0;">&nbsp;</td></tr>`;

export const build = (rows) => {
  let product = '';
  rows.forEach((row) => {
    let tempRow = template;
    let typeColor;
    switch (row.type.toLowerCase()) {
      case 'buy':
        typeColor = '#009607';
        break;
      case 'sell':
        typeColor = '#c31200';
        break;
      case 'buy to cover':
        typeColor = '#c31200';
        break;
      case 'buy to close':
        typeColor = '#c31200';
        break;
      case 'sell short':
        typeColor = '#009607';
        break;
      default:
        typeColor = '#009607';
        break;
    }
    tempRow = tempRow.replace('$typeColor', typeColor);
    tempRow = tempRow.replace('$type', row.type);
    let trade = row.trade.replace(`${row.type} `, '');
    trade = trade.replace(row.company, `<strong>${row.company}</strong>`);
    tempRow = tempRow.replace('$trade', trade);
    tempRow = tempRow.replace('$link', row.link);
    tempRow = tempRow.replace('$service', row.service);
    product += `${tempRow}\n`;
  });
  return product;
};
