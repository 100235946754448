import React, { useEffect, useState } from 'react';
import { Draftail } from '../../../components/draftail';
import BroadcastOptionsForm from '../../../components/BroadcastOptionsForm';
import { Card, CardBody, Col, Row, FormGroup, Input, Label } from 'reactstrap';
import useBreakpoint from '../../../utils/hooks/useBreakpoint';

function ContentAndOptions({
  initialHtml,
  setHtml,
  inputFields,
  options,
  setOptions,
  wordPress,
  externalKey,
  setIsValid,
  renderNameAndTags,
}) {
  // Modifier To Force Editor Render
  // eslint-disable-next-line
  const [keyModifier, setKeyModifier] = useState(0);
  const [showNameAndTags, setShowNameAndTags] = useState(
    localStorage.getItem('showNameAndTags') === 'true' || false,
  );
  const [showWebFields, setShowWebFields] = useState(
    localStorage.getItem('showWebFields') === 'true' || false,
  );
  const breakpoint = useBreakpoint();

  useEffect(() => {
    localStorage.setItem('showNameAndTags', showNameAndTags);
    localStorage.setItem('showWebFields', showWebFields);
  }, [showNameAndTags, showWebFields]);

  return (
    <Row>
      <Col>
        <Draftail
          key={`rich-content-editor-${keyModifier}-${externalKey}`}
          initialHtml={initialHtml}
          setHtml={setHtml}
          showTopToolbar={true}
        />
      </Col>
      <Col md={4}>
        <Card outline color="white">
          <CardBody className="pt-0">
            {renderNameAndTags && (
              <>
                <FormGroup check inline className="mb-3 mt-0 pt-0">
                  <Input
                    type="checkbox"
                    name="show-name-and-tags"
                    checked={showNameAndTags}
                    onChange={(e) => setShowNameAndTags((old) => !old)}
                  />
                  <Label check>Name & Tags</Label>
                </FormGroup>
              </>
            )}
            {wordPress && (
              <>
                <FormGroup check inline className="mb-3 mt-0 pt-0">
                  <Input
                    type="checkbox"
                    name="show-web-taxonomies"
                    checked={showWebFields}
                    onChange={(e) => setShowWebFields((old) => !old)}
                  />
                  <Label check>Taxonomies & Featured Image</Label>
                </FormGroup>
              </>
            )}
            {showNameAndTags && <>{renderNameAndTags()}</>}
            <BroadcastOptionsForm
              showWebFields={showWebFields}
              inputFields={inputFields}
              options={options}
              wordPress={wordPress}
              setOptions={setOptions}
              setIsValid={setIsValid}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default ContentAndOptions;
