import { EditorState, ContentState, Modifier, convertFromHTML } from 'draft-js';

const createHandlePastedText = () => {
  const handlePastedText = (text, html, editorState, { setEditorState }) => {
    const blocksFromHTML = convertFromHTML(handleMicrosoftNewlines(html));
    const newState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    const finalState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      newState.getBlockMap(),
    );
    setEditorState(
      EditorState.push(editorState, finalState, 'insert-fragment'),
    );
    return 'handled';
  };

  return {
    handlePastedText: handlePastedText,
  };
};

const handleMicrosoftNewlines = (html) => {
  const microsoftCopyPasteStartFragment =
    /^<html[^]*<!--StartFragment-->[^]*?</;
  const microsoftCopyPasteEndFragment = />[^<]*?<!--EndFragment-->[^]*$/;

  const startFragment = microsoftCopyPasteStartFragment.exec(html);
  const cleanedStartFragment = startFragment
    ? startFragment[0].replace(/>\s+</g, '><')
    : '';

  const endFragment = microsoftCopyPasteEndFragment.exec(html);
  const cleanedEndFragment = endFragment
    ? endFragment[0].replace(/>\s+</g, '><')
    : '';

  return html
    .replace(microsoftCopyPasteStartFragment, cleanedStartFragment)
    .replace(microsoftCopyPasteEndFragment, cleanedEndFragment);
};

export default createHandlePastedText;
