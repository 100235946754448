import React, { useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  InputGroup,
  InputGroupText,
  Input,
  FormGroup,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faCopy } from '@fortawesome/pro-duotone-svg-icons';
import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faTags,
} from '@fortawesome/pro-regular-svg-icons';
import UserAvatar from '../../components/UserAvatar';
import NewItemButton from '../../components/NewItemButton';
import NewBroadcastModal from '../../components/NewBroadcastModal';
import { useConfirmActionModal } from '../../utils/contexts/confimActionModalContext';
import {
  useAllQuery,
  usePagination,
  usePrefetchAll,
} from '../../utils/hooks/reactQuery/queries';
import { useItemMutation } from '../../utils/hooks/reactQuery/mutations';
import PaginationBar from '../../components/PaginationBar';

function Broadcasts() {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [searchValue, setSearchValue] = useState('');
  const [queryState, setQueryState] = useState({
    query: '',
    sort: 'modifiedAt',
    order: -1,
    page: 1,
    limit: 10,
    tags: [],
    testEnabled: false,
    live: true,
    launched: false,
    inProgress: true,
    mine: true,
  });
  const { data, isLoading, isError, error } = usePagination(
    'broadcasts',
    queryState,
  );
  const { data: tags = [] } = useAllQuery('tags');
  const { setConfirmActionModal } = useConfirmActionModal();
  const { add, _delete } = useItemMutation('broadcast');
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  // PREFETCH
  usePrefetchAll('broadcast-templates');
  usePrefetchAll('placeholder-mappings');

  useEffect(() => {
    if (searchParams) {
      const searchParamsObj = Object.fromEntries(searchParams.entries());
      if (Object.keys(searchParamsObj).length > 0) {
        setQueryState((old) => ({
          ...old,
          ...searchParamsObj,
          order: searchParamsObj.order
            ? parseInt(searchParamsObj.order)
            : old.order,
          page: searchParamsObj.page
            ? parseInt(searchParamsObj.page)
            : old.page,
          limit: searchParamsObj.limit
            ? parseInt(searchParamsObj.limit)
            : old.limit,
          tags: searchParamsObj.tags
            ? searchParamsObj.tags.split(',')
            : old.tags,
          testEnabled: searchParamsObj?.testEnabled === 'true',
          launched: searchParamsObj?.launched === 'true',
          live: searchParamsObj?.live === 'true',
          inProgress: searchParamsObj?.inProgress === 'true',
          mine: searchParamsObj?.mine === 'true',
        }));
        setSearchValue(searchParamsObj.query || '');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchParams({ ...queryState, tags: queryState.tags.join(',') });
  }, [queryState, setSearchParams]);

  // method to update our search value via debounce
  const handleQueryChange = (value) => {
    console.log('setting query');
    setQueryState((old) => ({ ...old, query: value }));
  };

  // For debouncing our search field
  const debouncedResults = useMemo(() => {
    return debounce(handleQueryChange, 300);
  }, []);

  // on unmount we want to clean up our debounce
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  // When the query changes we want to update our search
  useEffect(() => {
    console.log('search value changed');
    debouncedResults(searchValue);
  }, [searchValue, debouncedResults]);

  // Set the field to sort by
  const updateSortBy = (field) => {
    // Are we clicking the same field?
    // if so we will reverse the sorting
    let clone = { ...queryState };
    if (queryState.sort === field) {
      clone.order = queryState.order * -1;
    }
    setQueryState({
      ...clone,
      sort: field,
    });
  };

  const popTable = (broadcasts) => {
    return broadcasts.map((broadcast) => (
      <tr key={broadcast._id}>
        <td className="align-middle">{broadcast.name}</td>
        <td className="align-middle">
          {broadcast.modifiedBy ? (
            <div className="d-flex align-items-center">
              <UserAvatar user={broadcast.modifiedBy} />
              <div className="ms-3">
                <p className="fw-bold mb-1">{`${broadcast.modifiedBy.firstName} ${broadcast.modifiedBy.lastName}`}</p>
                <p className="text-muted mb-0">
                  {broadcast.modifiedBy.username}
                </p>
              </div>
            </div>
          ) : (
            'Unknown user...'
          )}
        </td>
        <td className="align-middle">
          {new Date(broadcast.modifiedAt).toLocaleString([], {
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </td>
        <td className="align-middle">
          <Link to={broadcast._id} className="text-decoration-none">
            <Button
              color="cpblue-500"
              className="rounded-circle d-block mx-auto"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </Link>
        </td>
        <td className="align-middle">
          <Button
            color="cpblue-200"
            className="rounded-circle d-block mx-auto"
            onClick={() => {
              let clone = { ...broadcast };
              delete clone._id;
              delete clone.status;
              clone.name = `COPY - ${clone.name}`;
              add.mutate(clone, {
                onError: (error) => {
                  console.error(error);
                },
              });
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </td>
        <td className="align-middle">
          <Button
            color="cpred-500"
            className="rounded-circle d-block mx-auto"
            onClick={() =>
              setConfirmActionModal({
                open: true,
                title: 'Confirm Delete?',
                severity: 'warning',
                message: `Are you sure you'd like to delete ${broadcast.name}?`,
                onClose: (confirmed) => {
                  if (confirmed) {
                    const toastId = toast.loading(
                      `Deleting ${broadcast.name}...`,
                    );
                    _delete.mutate(broadcast._id, {
                      onSuccess: () => {
                        console.log('Deleted Broadcast!');
                        toast.update(toastId, {
                          render: `Deleted ${broadcast.name}!`,
                          type: 'success',
                          isLoading: false,
                          autoClose: 1500,
                        });
                      },
                      onError: (error) => {
                        console.error(error);
                        toast.update(toastId, {
                          render: 'Error deleting...',
                          type: 'error',
                          isLoading: false,
                          autoClose: 1500,
                        });
                      },
                    });
                  }
                },
              })
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </td>
      </tr>
    ));
  };

  const popFillerRows = ({ docs, limit }) => {
    const rows = [];

    for (let i = 0; i < limit - docs.length; i++) {
      rows.push(
        <tr key={i}>
          <td className="align-middle" style={{ height: '58px' }}>
            &nbsp;
          </td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
          <td className="align-middle"></td>
        </tr>,
      );
    }

    return rows;
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Broadcasts</h1>
          <p>Create, Delete, and Update Broadcasts here.</p>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <NewItemButton
            extraClasses="me-auto"
            text="Create Broadcast"
            customOnClick={() => setNewModalOpen(true)}
          />
          <Dropdown
            isOpen={filtersOpen}
            toggle={() => setFiltersOpen(!filtersOpen)}
          >
            <DropdownToggle tag={'div'} style={{ cursor: 'pointer' }}>
              <span className="me-3 fs-6 fw-light">Filters</span>
              <span>
                <FontAwesomeIcon icon={faChevronDown} size="sm" />
              </span>
            </DropdownToggle>
            <DropdownMenu
              end
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 15],
                  },
                },
              ]}
            >
              <DropdownItem tag={'div'} toggle={false}>
                <FormGroup switch inline>
                  <Input
                    type="switch"
                    role="switch"
                    checked={queryState.mine}
                    onChange={(e) =>
                      setQueryState((old) => ({
                        ...old,
                        mine: e.target.checked,
                      }))
                    }
                  />
                  <Label check>
                    {queryState.mine ? 'Only Mine' : "Everyone's"}
                  </Label>
                </FormGroup>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={'div'} toggle={false}>
                <FormGroup check inline>
                  <Input
                    type="checkbox"
                    name="in-progress"
                    checked={queryState.inProgress}
                    onChange={(e) =>
                      setQueryState((old) => ({
                        ...old,
                        inProgress: e.target.checked,
                      }))
                    }
                  />
                  <Label check>In Progress</Label>
                </FormGroup>
              </DropdownItem>
              <DropdownItem tag={'div'} toggle={false}>
                <FormGroup check inline>
                  <Input
                    type="checkbox"
                    name="launched"
                    checked={queryState.launched}
                    onChange={(e) =>
                      setQueryState((old) => ({
                        ...old,
                        launched: e.target.checked,
                      }))
                    }
                  />
                  <Label check>Launched</Label>
                </FormGroup>
              </DropdownItem>
              <DropdownItem tag={'div'} toggle={false}>
                <FormGroup check inline>
                  <Input
                    type="checkbox"
                    name="live"
                    checked={queryState.live}
                    onChange={(e) =>
                      setQueryState((old) => ({
                        ...old,
                        live: e.target.checked,
                      }))
                    }
                  />
                  <Label check>Live</Label>
                </FormGroup>
              </DropdownItem>
              <DropdownItem tag={'div'} toggle={false}>
                <FormGroup check inline>
                  <Input
                    type="checkbox"
                    name="test-enabled"
                    checked={queryState.testEnabled}
                    onChange={(e) =>
                      setQueryState((old) => ({
                        ...old,
                        testEnabled: e.target.checked,
                      }))
                    }
                  />
                  <Label check>Test</Label>
                </FormGroup>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <InputGroup className="w-25 ms-3">
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
            <Input
              type="text"
              name="search"
              id="search"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
            />
          </InputGroup>
          <InputGroup className="w-25 ms-2">
            <InputGroupText>
              <FontAwesomeIcon icon={faTags} />
            </InputGroupText>
            <Typeahead
              id="tags"
              name="tags"
              placeholder="Tags"
              multiple
              labelKey="name"
              options={tags || []}
              onChange={(selected) =>
                setQueryState((old) => ({
                  ...old,
                  tags: selected.map((tag) => tag.name),
                }))
              }
              selected={tags.filter((tag) =>
                queryState.tags.includes(tag.name),
              )}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          {isLoading ? (
            <h5>Loading...</h5>
          ) : isError ? (
            <h5>Error: {error.message}</h5>
          ) : (
            <Row>
              <Col>
                <Card className="rounded shadow border-0">
                  <CardBody>
                    <Table borderless striped responsive>
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: 'pointer' }}
                            onClick={() => updateSortBy('name')}
                          >
                            Name
                            {queryState.sort === 'name' && (
                              <>
                                {' '}
                                <FontAwesomeIcon
                                  icon={
                                    queryState.order === 1
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                  className="float-end"
                                />
                              </>
                            )}
                          </th>
                          <th
                            style={{ cursor: 'pointer' }}
                            onClick={() => updateSortBy('modifiedBy.firstName')}
                          >
                            Modified By
                            {queryState.sort === 'modifiedBy.firstName' && (
                              <>
                                {' '}
                                <FontAwesomeIcon
                                  icon={
                                    queryState.order === 1
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                  className="float-end"
                                />
                              </>
                            )}
                          </th>
                          <th
                            style={{ cursor: 'pointer' }}
                            onClick={() => updateSortBy('modifiedAt')}
                          >
                            Modified At
                            {queryState.sort === 'modifiedAt' && (
                              <>
                                {' '}
                                <FontAwesomeIcon
                                  icon={
                                    queryState.order === 1
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                  className="float-end"
                                />
                              </>
                            )}
                          </th>
                          <th className="text-center">Edit</th>
                          <th className="text-center">Clone</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {popTable(data.docs)}
                        {popFillerRows(data)}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Row className="mt-5">
                <Col className="d-flex justify-content-center">
                  <PaginationBar
                    currentPage={data.page}
                    totalPages={data.totalPages}
                    setPage={(newPage) =>
                      setQueryState((old) => ({ ...old, page: newPage }))
                    }
                    perPage={data.limit}
                    setPerPage={(newPerPage) =>
                      setQueryState((old) => ({ ...old, limit: newPerPage }))
                    }
                  />
                </Col>
              </Row>
            </Row>
          )}
        </Col>
      </Row>
      <NewBroadcastModal open={newModalOpen} setOpen={setNewModalOpen} />
    </Container>
  );
}

export default Broadcasts;
