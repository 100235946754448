import React, { useRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCamera } from '@fortawesome/pro-duotone-svg-icons';
import { useField } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import UserAvatar from '../UserAvatar';
import { apiUrl } from '../../utils/url';
import * as jwt from '../../utils/jwt';
import { rewriteS3ImageUrls } from '../../utils/rewriteUrls';

function UserProfileImageInput({ user }) {
  const [, , { setValue }] = useField('profileImage');
  const fileInputRef = useRef(null);

  const imageUploadHandler = async (e) => {
    const toastId = toast.loading('Uploading image...');
    // Get our file
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      // create and append some form data
      const formData = new FormData();
      formData.append('image', file);
      formData.append('bucketName', 'email.oxfordclub.com');
      formData.append('path', 'copypasta/profile-pictures/');

      // Get our JWT and config our options
      const myJwt = jwt.getJwt();
      if (myJwt) {
        const options = {
          method: 'POST',
          url: `${apiUrl}s3-images/upload`,
          headers: {
            Authorization: `Bearer ${myJwt}`,
          },
          data: formData,
        };

        try {
          const response = await axios.request(options);
          setValue(rewriteS3ImageUrls(response.data.Location), true);
          toast.update(toastId, {
            render: 'Profile image uploaded!',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
        } catch (error) {
          console.error(error);
          toast.update(toastId, {
            render: 'Could not upload image...',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
        }
      } else {
        console.error(new Error('Unable to authenticate...'));
        toast.update(toastId, {
          render: 'Could not authenticate...',
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      }
    }
  };

  return (
    <>
      <UserAvatar user={user} size={128}>
        {(avatar, isImage) => (
          <Row className="mb-5">
            <Col className="d-flex justify-content-center">
              <div className="position-relative">
                {avatar}
                <Button
                  className="position-absolute bottom-0 end-0 translate-end rounded-circle border border-3 border-white"
                  color={isImage ? 'cpred-500' : 'cpblue-500'}
                  onClick={
                    isImage
                      ? () => setValue('', true)
                      : () =>
                          fileInputRef.current && fileInputRef.current.click()
                  }
                >
                  <FontAwesomeIcon icon={isImage ? faTrash : faCamera} />
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </UserAvatar>
      <input
        ref={fileInputRef}
        type="file"
        id="image-upload"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={imageUploadHandler}
      />
    </>
  );
}

export default UserProfileImageInput;
