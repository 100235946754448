import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Progress,
  Row,
} from 'reactstrap';
import useBreakpoint from '../../utils/hooks/useBreakpoint';

function FormCard({
  breadcrumbLink = null,
  breadcrumbLabel = null,
  breadcrumbItem = null,
  progressValue = null,
  progressTitle = null,
  numSteps = null,
  extraInline = null,
  useCard = true,
  children,
}) {
  const breakpoint = useBreakpoint();

  return (
    <>
      {breadcrumbLink && breadcrumbLabel && breadcrumbItem && (
        <Row>
          <Col>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={breadcrumbLink}>{breadcrumbLabel}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{breadcrumbItem}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          {extraInline && <Col>{extraInline}</Col>}
        </Row>
      )}
      {progressValue && progressTitle && numSteps && (
        <Row>
          <Col className="text-center">
            <h4>{progressTitle}</h4>
            <Progress
              max={numSteps}
              value={progressValue}
              animated
              color="cpblue-600"
            />
          </Col>
        </Row>
      )}
      <Row className="pt-3">
        <Col>
          {useCard ? (
            <Card
              className={`${
                breakpoint !== 'xs' && breakpoint !== 'sm' && 'shadow'
              } rounded border-0`}
            >
              <CardBody>{children}</CardBody>
            </Card>
          ) : (
            children
          )}
        </Col>
      </Row>
    </>
  );
}

export default FormCard;
